import usericon from './images/png/user.png';
import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import loginbg from './images/png/login-bg.png';
import userlist1 from './images/png/userlist1.png';
import bergurmenu from './images/png/bergur-menu.png';
import close from './images/png/close.png';
import blue from './images/png/blue.png';
import red from './images/png/red.png';
import green from './images/png/green.png';
import yellow from './images/png/yellow.png';
import userProfile from './images/png/userchatpng.png';
import pagenotfound from './images/png/pagenotfound.png';
const Png = {
      usericon,
      logo,
      edit,
      loginbg,
      userlist1,
      bergurmenu,
      close,
      blue,
      yellow,
      green,
      red,
      userProfile,
      pagenotfound
}

export default Png;