import React, { useEffect, useState } from "react";
import Index from "../../Index";
import { Link, useNavigate } from "react-router-dom";
import { DataServices } from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { useDispatch, useSelector } from "react-redux";
import { Adminlogout } from "../../../redux/admin/action";

export default function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imageAdd = useSelector((Store) => Store.ImageReducer);
  const {token,notificationData} = useSelector((state) => state.AdminReducer);

  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState("");

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAdminProfile = async () => {
    const formData = new URLSearchParams();
    DataServices(token)
      .get(Api.Admin.getAdminProfile, formData)
      .then((response) => {
        setImage(response.data.data.profileImg)
      });
  };

  useEffect(() => {
    if (token != "") {
      getAdminProfile();
    }
  }, [imageAdd]);

  const openSidebar = (event) => {
    if (props.open) {
      document.body.classList.add("body-overflow");
    } else {
      document.body.classList.remove("body-overflow");
    }
  };

  useEffect(() => {
    openSidebar();
  }, [props?.open]);

  const SignOut = () => {
    dispatch(Adminlogout(navigate));
  };



  return (
    <>
      <Index.Box
        className={`admin-header-main ${
          props.open ? "active" : "admin-header-deactive"
        }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={Index.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              className="admin-bergur-button"
              onClick={() => {
                props.setOpen(!props.open);
                openSidebar();
              }}
            >
              <img
                src={Index.Svg.bergurmenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-header-right">
            <Index.Box 
            className="admin-header-icon-box"
             onClick={() => navigate("/admin/notification")}
            ><Index.Badge badgeContent={notificationData?.length} color="primary">
              <img
                src={Index.Svg.notification}
                className="admin-header-icon"
                alt="dashboard logo"
              ></img>
              </Index.Badge>
            </Index.Box>
            <Index.Box
              className="admin-header-icon-box"
              onClick={() => navigate("/admin/general-setting")}
            >
              <img
                src={Index.Svg.settings}
                className="admin-header-icon"
                alt="dashboard bell icon"
              ></img>
            </Index.Box>
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={`http://35.177.56.74:3010/images/${image}`}
                    className="admin-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  {/* <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      Admin
                    </Index.Typography>
                  </Index.Box> */}
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/admin/edit-profile");
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={Index.Svg.profilegrey} className="drop-header" />{" "}
                <Link className="profile-header"> Profile</Link>
              </Index.MenuItem>
              {/*  */}
              {/* <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                <img src={Index.Svg.settings} className="drop-header" /> Account
                Settings
              </Index.MenuItem> */}
              <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                <img src={Index.Svg.edit} className="drop-header" />
                <Link
                  className="sign-out-admin"
                  to="/"
                  onClick={() => SignOut()}
                >
                  Sign Out
                </Link>
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
