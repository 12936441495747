import { combineReducers } from 'redux';
import AdminReducer from './admin/reducer';
import UserReducer from './admin/reducer';
import ImageReducer from './image/reducer';
const rootReducer = combineReducers({
  UserReducer: UserReducer,
  AdminReducer:AdminReducer,
  ImageReducer:ImageReducer
});

export default rootReducer;
