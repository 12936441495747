export default {
  fetchAdminLoginSuccess: 'fetchAdminLoginSuccess',
  AdminlogOutSuccess: 'AdminlogOutSuccess',
  ADMIN_LOGIN:'ADMIN_LOGIN',
  LOGOUT:"LOGOUT",
  ADMIN_ERROR_HANDLER:"ADMIN_ERROR_HANDLER",
  EDIT_PROFILE : 'EDIT_PROFILE',
  GENERAL_SETTING :'GENERAL_SETTING',
  BETLIST : "BETLIST",
  BETLIST_LOADING : " BETLIST_LOADING",
  ADD_BET : "ADD_BET",
  CHAT_API : 'CHAT_API',
  GET_GENERAL_SETTING:"GET_GENERAL_SETTING",
  GET_NOTIFICATION:"GET_NOTIFICATION",
  GET_NOTIFICATION_LOADING:"GET_NOTIFICATION_LOADING",
  };
