import React, { useState } from "react";
import Index from "../../../component/Index";
import { Api } from "../../../config/Api";
import { DataServices } from "../../../config/DataService";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";

import { validationSchemaOtpVerify } from "../../../validation/validation";
import { Formik } from "formik";
import OTPInput from "react-otp-input";
import ErrorShow from "../../../component/common/ErrorShow";
import { useEffect } from "react";
import { useRef } from "react";

export default function Otp() {
  const [loading, setLoading] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(false);
  // const declaration
  const navigate = useNavigate();

  const { state } = useLocation()
  const intervalRef = useRef(null)
  let resetId = state ? state : null;
  // Initital values declaration
  let initialValues = {
    otp: "",
  };

  // functions declaration
  const handleFormSubmit = (values) => {
    DataServices().post(Api.Admin.OtpVerify, { ...values, id: resetId })
      .then((response) => {
        toast.success(response?.data?.message);
        navigate("/resetpassword", { state: response?.data?.data?.forgotId })
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  const resendOtp = async () => {
    setMinutes(0);
    setSeconds(10);
    setDisabled(true);


    await DataServices().post(
      Api.Admin.resendOtpData, { id: resetId }

    ).then((response) => {
      toast.success(response?.data?.message);
      setLoading(true)
    })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });

  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(intervalRef.current);
          setDisabled(false);
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [resendOtp]);
  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  OTP!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to OTP!
                </Index.Typography>
                <Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={
                    validationSchemaOtpVerify
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form

                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="input-box">

                        <Index.Box className="otp">
                          <OTPInput
                            name="otp"
                            inputStyle="inputStyle"
                            numInputs={4}
                            inputType="number"
                            renderSeparator={<span> </span>}
                            value={values.otp}
                            // validateChar="number"
                            onChange={(file) => setFieldValue("otp", file)}
                            error={Boolean(errors.otp)}
                            className="otp-design-div otp-placeholder"
                            renderInput={(props) => <input {...props} />}
                          />

                        </Index.Box>
                        <ErrorShow error={errors.otp} />
                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button className="btn-primary login-btn" type="submit">
                          Send
                        </Index.Button>
                      </Index.Box>
                    </form>
                  )}
                </Formik>

              </Index.Box>
              <Index.Box className="box-login-text resend-otp verify-box">
                {minutes > 0 || seconds > 0 ? (
                  <Index.Typography
                    variant="body1"
                    component="p"
                    className="resend-otp-code"
                  >
                    RESEND CODE :{" "}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </Index.Typography>
                ) : (
                  <Index.Button
                    onClick={resendOtp}
                    disabled={disabled}
                  >
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Resend code
                    </Index.Typography>
                  </Index.Button>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
