import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import { useDispatch, useSelector } from "react-redux";
import CoinList from "../withdrawal/CoinList";
import Loading from "../../../../component/common/loading/Loading"
import AllUsers from "../../../../assets/images/png/AllUsers.png"
import OnlineUsers from "../../../../assets/images/png/OnlineUsers.png"
import RefralUsers from "../../../../assets/images/png/RefralUsers.png"
import ReedemCoin from "../../../../assets/images/png/ReedemCoin.png"
import OnlineTeams from "../../../../assets/images/png/OnlineTeams.png"
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";
import { getNotification } from "../../../../redux/admin/action";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.FailedLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PendingLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
];

export default function Home() {
  const [userList, setUserList] = useState([]);
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [reedemCoin, setReedemCoin] = useState();
  const [coinList, setCoinList] = useState([]);
  const [onlineUser, setOnlineUser] = useState([]);
  const [reffrelUser, setReffrelUser] = useState([]);
  const [activeRoom, setActiveRoom] = useState([]);
  const [totalRoom, setTotalRoom] = useState([]);
  const [coinListLoading, setCoinListLoading] = useState(true)
  const [withdrawalListLoading, setWithdrawalListLoading] = useState(true)


  const token = useSelector((state) => state?.AdminReducer?.token);
  const dispatch = useDispatch()
  const getAllUsers = async () => {
    const response = await DataServices(token).get(Api.Admin.getAllUsers);
    setUserList(response?.data?.data);
  };
  const getTotalActiveRooms = async () => {
    const response = await DataServices(token).get(Api.Admin.activeRooms);
    setActiveRoom(response?.data?.data);
  };
  const getTotalRooms = async () => {
    const response = await DataServices(token).get(Api.Admin.allRooms);
    setTotalRoom(response?.data?.data);
  };

  const showonlineusers = async () => {
    const response = await DataServices(token).get(Api.Admin.showonlineusers);
    setOnlineUser(response?.data);
  };
  const getReedemCoin = async () => {
    const response = await DataServices(token).get(
      Api.Admin.allTransaction + "?type=withdrawal" + "&Status=Success"
    );
    setReedemCoin(response?.data);
  };
  const getWithdrawalList = async () => {
    await DataServices(token).get(
      Api.Admin.allTransaction + `?type=withdrawal`
    ).then((response) => {
      if (response?.data?.status === 200) {

        setWithdrawalList(response?.data?.data);
        setWithdrawalListLoading(false)
      }
    })

  };

  const getCoinList = async () => {
    await DataServices(token).get(
      Api.Admin.allTransaction + `?type=Add`
    ).then((response) => {
      if (response?.data?.status === 200) {
        setCoinList(response?.data);
        setCoinListLoading(false)
      }
    })

  };

  const showrefrllUser = async () => {
    const response = await DataServices(token).get(Api.Admin.showrefrllUser);
    setReffrelUser(response?.data);
  };
  useEffect(() => {
    getAllUsers();
    getTotalActiveRooms();
    showrefrllUser();
    showonlineusers();
    getTotalRooms();
    getCoinList();
    getWithdrawalList();
    getReedemCoin();
    dispatch(getNotification(token))
  }, []);

  return (
    <>
      <Index.Box className="  ">
        <Index.Typography
          className="admin-page-title-dashboard"
          component="h2"
          variant="h2"
        >
          Dashboard
        </Index.Typography>
        <Index.Box className="admin-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Users
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {userList?.length}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-dash-right">
                      <img
                        src={AllUsers}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Online Users
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {onlineUser?.data}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={OnlineUsers}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Referred Users
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {reffrelUser?.data}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={RefralUsers}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Rooms
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {totalRoom}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.dashicon2}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Active Rooms
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {activeRoom}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="admin-dash-right">
                      <img
                        src={OnlineTeams}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Redeemed Coins
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {coinList?.totalAmount}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={ReedemCoin}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              ></Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              ></Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Withdrawal
                      </Index.Typography>
                    </Index.Box>

                    {/* <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button
                        className="view-order-btn border-btn"
                        btnLabel="View Orders"
                      >
                        View Orders
                      </Index.Button>
                    </Index.Box> */}
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USER NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              AMOUNT
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!withdrawalListLoading ? withdrawalList?.length ? withdrawalList?.slice(0, 10).map((row) => (
                            <Index.TableRow
                              key={row?.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.transactionId}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.user?.firstName +
                                  " " +
                                  row?.user?.lastName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{
                                  color:
                                    row?.Status === "Success"
                                      ? "green"
                                      : row?.Status === "Pending"
                                        ? "blue"
                                        : "red",
                                }}
                              >
                                {row?.Status === "Success" ? "Approved" : (row?.Status === "Reject" ? "Rejected" : "Pending")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {moment(row?.date).format("lll")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.amount}
                              </Index.TableCell>
                            </Index.TableRow>
                          )) :
                            <NoRecordFound colSpan={3} />
                            : <Loading colSpan={3} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Coin
                      </Index.Typography>
                    </Index.Box>
                    {/* <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button className="view-order-btn border-btn">
                        View Orders
                      </Index.Button> 
                    </Index.Box> */}
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USER NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              AMOUNT
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!coinListLoading ? coinList?.data?.length ? coinList?.data?.slice(0, 10).map((row) => (
                            <Index.TableRow
                              key={row?.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.transactionId}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.user?.firstName +
                                  " " +
                                  row?.user?.lastName}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{
                                  color:
                                    row?.Status === "Success"
                                      ? "green"
                                      : row?.Status === "Pending"
                                        ? "blue"
                                        : "red",
                                }}
                              >
                                {row?.Status === "Success" ? "Approved" : (row?.Status === "Reject" ? "Rejected" : "Pending")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {moment(row?.date).format("lll")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.amount}
                              </Index.TableCell>
                            </Index.TableRow>
                          )) :
                            <NoRecordFound colSpan={3} />
                            :
                            <Loading colSpan={3} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {/* <Index.Box className="progress-bar-content">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Progress Bar
          </Index.Typography>
          <Index.Box className="progress-bar-main">
            <BorderLinearProgress
              variant="determinate"
              value={50}
              className="progress-bar"
            />
            <span className="progress-bar-text">50%</span>
          </Index.Box>
        </Index.Box> */}
      </Index.Box>
    </>
  );
}
