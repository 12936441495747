import type from "./type";
import {
  loginApi,
  editProfileApi,
  GeneralSettingApi,
  getBetListApi,
  addBetApi,
  adminUserChatApi,
  getGeneralSettingApi,
  notificationApi,
} from "../../services/AdminIndex";
import { toast } from "react-toastify";


const AdminLoginFetch = (payload) => ({
  type: type.fetchAdminLoginSuccess,
  payload,
});

const AdminLogOutAction = (payload) => ({
  type: type.AdminlogOutSuccess,
  payload,
});

const AdminLogin = (payload, navigate) => {
  return async function (dispatch) {
    loginApi(payload).then((data) => {
      if (data?.status === 200) {
        dispatch({ type: type.ADMIN_LOGIN, payload: data });
        navigate("/admin/dashboard");
        toast.success(data?.message)
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

const Adminlogout = (navigate) => {
  return async function (dispatch) {
    dispatch({ type: type.LOGOUT });
    navigate("/")
    toast.success("Successfully sign-out")

  };
};

const EditProfile = (payload, token) => {
  return async function (dispatch) {
    editProfileApi(payload, token).then((data) => {
      if (data?.status === 200) {
   
        toast.success(data?.message)
    
        dispatch({ type: type.EDIT_PROFILE, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

const GeneralSetting = (payload, token) => {
  return async function (dispatch) {
    GeneralSettingApi(payload, token).then((data) => {

      if (data?.status === 200) {
        dispatch(getGeneralSetting(token))
        dispatch({ type: type.GENERAL_SETTING, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
const getGeneralSetting = (token) => {
  return async function (dispatch) {
   await getGeneralSettingApi(token).then((data) => {

      if (data?.status === 200) {
        
        dispatch({ type: type.GET_GENERAL_SETTING, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

const addBet = (payload, token) => {
  return async function (dispatch) {
    addBetApi(payload, token).then((data) => {
      
      if (data?.status === 201) {
        dispatch(getBetList(token))
        toast.success(data?.message)
      
        dispatch({ type: type.ADD_BET, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};
const getBetList = (token) => {
  return async function (dispatch) {

    dispatch({ type: type.BETLIST_LOADING, payload:"" });

    getBetListApi(token).then((data) => {
      if (data?.status === 200) {
        dispatch({ type: type.BETLIST, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

const adminUserChat = (params, navigate, user) => {
  return async function (dispatch) {
    adminUserChatApi(params).then((data) => {
      
      if (data?.status === 200) {
        dispatch({ type:type.CHAT_API, payload:data });
        
        const userId = user?.userId
        if (userId) {

          navigate(`/admin/user-chat/${data.data.roomID}/${userId}`, { state: { user } })
        }
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

const getNotification = (token) => {
  return async function (dispatch) {

    dispatch({ type: type.GET_NOTIFICATION_LOADING, payload:"" });

    notificationApi(token).then((data) => {
      if (data?.status === 200) {
        dispatch({ type: type.GET_NOTIFICATION, payload:data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload:data });
      }
    });
  };
};

export {
  AdminLoginFetch,
  AdminLogOutAction,
  AdminLogin,
  Adminlogout,
  EditProfile,
  GeneralSetting,
  getBetList,
  addBet,
  adminUserChat,
  getGeneralSetting,
  getNotification
};
