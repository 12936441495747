import { ClipLoader } from "react-spinners";
import Index from "../../Index";



const Loading = (props) => (

    <Index.TableRow>
        <Index.TableCell colSpan={props.colSpan} >

            <Index.Box className="loading-spinner" >
                <ClipLoader

                    color="red"
                    loading="loading"
                    // cssOverride={override}
                    size={50}

                // aria-label="Loading Spinner"
                // data-testid="loader"
                />
            </Index.Box>


        </Index.TableCell>
    </Index.TableRow>
);

export default Loading