import React, { useEffect, useState } from 'react'
import Index from "../../../Index";
import { DataServices } from '../../../../config/DataService';
import { Api } from '../../../../config/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getNotification } from '../../../../redux/admin/action';

const Notification = () => {


  const { notificationData, loading, token } = useSelector((state) => state?.AdminReducer)

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const notificationRemove = async (id, user) => {
    await DataServices(token).post(Api.Admin.removeNotification, { id: id })
      .then((res) => {
        if (res?.data?.status === 200) {
          if (user) {
            navigate('/admin/coin-list')
            dispatch(getNotification(token))
          } else {
            dispatch(getNotification(token))
          }
        }

      }).catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    dispatch(getNotification(token))
  }, [])



  return (
    <Index.Box>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Notifications
          </Index.Typography>
        </Index.Box>

        <Index.Box className="notification-main-box">
          {!loading ? notificationData?.length ? notificationData?.map((item) => {
            return (
              <Index.Box className="notification-box" >
                <Index.Tooltip title={item?.user?.firstName}  >
                  <Index.Box className="notification-message-box" onClick={() => { notificationRemove(item?._id, item?.user?.firstName) }}>
                    {/* <Index.Box className="notification-image">
                    <img src={!item.user.profileImg.length?`http://35.177.56.74:3010/images/${item.profileImg}`:{userChatPng}} />
                  </Index.Box> */}
                    <Index.Avatar src={item?.user?.profileImg ? `http://35.177.56.74:3010/images/${item?.user?.profileImg}` : Index.Png.usericon} />
                    <Index.Box className="notification-message-title-box">

                      <Index.Typography className='notification-title'>{item?.title}</Index.Typography>
                      <Index.Typography className='notification-message'>{item?.message}</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Tooltip>
                <Index.IconButton onClick={() => { notificationRemove(item._id); getNotification() }}>
                  <Index.CloseIcon />
                </Index.IconButton>
              </Index.Box>
            )
          }) :
            <Index.Typography className="table-data-not-found">RECORD NOT FOUND</Index.Typography>
            : <Index.Box className="loading-spinner" >
              <Index.ClipLoader
                color="red"
                loading="loading"
                size={50}
              />
            </Index.Box>}


        </Index.Box>
      </Index.Box>
    </Index.Box>
  )
}

export default Notification