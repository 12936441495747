import './App.css';
import Routes from './routes/Routes'
import './assets/style/admin.css';
import './assets/style/responsive.css';


// import ImageProvider from './context/imageContext';

function App() {
 
  
  return (
    <div className="App">
      {/* <ImageProvider> */}
      <Routes/>
      {/* </ImageProvider> */}
 
    </div>
  );
}

export default App;
