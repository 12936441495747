import { Avatar, AvatarGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Loading from "../../../../component/common/loading/Loading";
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function createData(name, calories) {
  return { name, calories };
}
const rows = [createData("test1", 1), createData("test2", 2)];

export default function RoomList() {
  // add user modal
  const token = useSelector((state) => state?.AdminReducer?.token);

  const [searchValue, setSearchValue] = useState("");
  const [actionRoomId, setActionRoomId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);
  const [roomList, setRoomList] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true)
  // const[rowData,setRowData]=useState({})
  const [open, setOpen] = React.useState(false);
  const [roomOpen, setRoomOpen] = React.useState(false);
  const [winnerData, setWinnerData] = useState([])
  const handleOpen = (status) => {
    setOpen(status)
    if (status === false) {
      toast.warning("Game Inprogress")
    }
  };
  const handleClose = () => {
    setOpen(false)

  };

  const handleRoomOpen = (data) => {
    setRoomOpen(data)
    if (data === false) {
      toast.warning("Game Inprogress")
    }
    // setRowData(row)
  };
  const handleRoomClose = () => setRoomOpen(false);
  const handlePageChange = (event, value) => {
    const val = Number(value);
    setCurrentPage(val);
  };

  const getAllUsers = async () => {
    await DataServices(token).get(Api.Admin.getAllroom).then((response) => {
      if (response?.data?.status === 200) {
        setRoomList(response?.data?.data)
        setFilterData(response?.data?.data)
        setLoading(false)
      }
    })


  };
  const getRoomDetails = async (id, status) => {
    const formData = new URLSearchParams();
    formData.append("roomId", id);
    if (!status === false) {
      await DataServices(token)
        .post(Api.Admin.getRoomByRoomId, formData)
        .then((response) => {
          setRoomData(response?.data?.data);
        });
    }

  };
  const getAllWinner = async (id, status) => {
    const formData = new URLSearchParams();
    formData.append("roomId", id);
    if (status) {


      const response = await DataServices(token).post(
        Api.Admin.getsinglerooominformation,
        formData
      );
      setWinnerData(response?.data?.data)

    }
  };

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = filterData?.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filterData?.length / userPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  const onsearch = async (e) => {
    setSearchValue(e.target.value);
    const filteredUsers = roomList?.filter((item) => {
      const topicIncludes = item?.roomId
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      return topicIncludes;
    });
    setFilterData(filteredUsers);
  };




  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Room List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={searchValue}
                    onChange={onsearch}
                    placeholder="Search room id...."
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">

            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main room-table-list game-bet-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Room ID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>


                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Users
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"

                            >
                              Bet Coin
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Winners
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!loading ? currentUsers.length ? currentUsers?.map((row, index) => {
                            const userCount = row?.usersCount
                            const result = Array.from({ length: userCount }, () => 0);

                            let date = moment(row?.createdAt).format(
                              "DD-MM-YYYY"
                            );
                            return (
                              <Index.TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td-click"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    handleRoomOpen(row?.isActive === true ? false : true);
                                    getRoomDetails(row?.roomId, row?.isActive === true ? false : true);

                                  }}
                                >
                                  {row.roomId}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {date}
                                </Index.TableCell>


                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td avatar-group"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <AvatarGroup>
                                    {row?.users?.map(() => (

                                      <Index.Avatar
                                        alt="Remy Sharp"
                                        src={Index.Png.userProfile}
                                      />

                                    ))}

                                  </AvatarGroup>



                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"

                                >
                                  {row?.betAmount}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"

                                >

                                  {row?.isActive == "false" || row?.winner?.firstName == null ? "Computer" : row?.winner?.firstName}

                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  style={{
                                    color: !row.isActive === true ? "green" : "red",
                                  }}
                                >
                                  <span
                                    class={
                                      !row?.isActive == true
                                        ? "activeStatus"
                                        : "deactiveStatus"
                                    }
                                  >
                                    {row?.isActive == true ? "Inprogress" : "Completed"}{" "}
                                  </span>
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <VisibilityIcon
                                    onClick={() => {
                                      handleOpen(row?.isActive === true ? false : true);
                                      getAllWinner(row?.roomId, row?.isActive === true ? false : true);
                                      setActionRoomId(row?.roomId);
                                    }}
                                    color="warning"
                                  />
                                </Index.TableCell>
                              </Index.TableRow>
                            )
                          }) : <NoRecordFound colSpan={12} />

                            : <Loading colSpan={12} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={pageNumbers?.length}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >

        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main-winner-details" onClick={handleClose}>
            <img
              src={Index.Svg.closecircle}
              className="user-circle-img"
              alt="icon"
            />
          </Index.Box>
          <Index.Box className="modal add-user-modal-inner-main-winner-details">

          
          <Index.Box className="modal-header"></Index.Box>
          <Index.Box className="winner-details-modal modal-input-box">
            <Index.FormHelperText className="form-lable">
              Room id : {winnerData?.roomId}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="winner-details-modal modal-input-box">
            <AvatarGroup >
              <Avatar
                className="avatar-modal"
                alt="Remy Sharp"
                src={winnerData?.winner?.profileImg != ''? `http://35.177.56.74:3010/images/${winnerData?.winner?.profileImg}` : Index.Png.userProfile}
              />
            </AvatarGroup>
          </Index.Box>
          <Index.Box className="winner-details-modal modal-input-box">
            <Index.FormHelperText className="form-lable">
              Winner name : {winnerData?.isActive == "false" || winnerData?.winner?.firstName == null ? "Computer" : winnerData?.winner?.firstName}
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="winner-details-modal modal-input-box">
            <Index.FormHelperText className="form-lable">
              Winning prize : {winnerData?.winningPrice
              }
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="winner-details-modal modal-input-box">
            <Index.FormHelperText className="form-lable">
              Played at : {moment(winnerData?.createdAt).format("DD-MM-YYYY")}
            </Index.FormHelperText>
          </Index.Box>
          </Index.Box>
        </Index.Box>

      </Index.Modal>

      <Index.Modal
        open={roomOpen}
        onClose={handleRoomClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >

        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">

          <Index.Box className="modal-circle-main" onClick={handleRoomClose}>
            <img
              src={Index.Svg.closecircle}
              className="user-circle-img"
              alt="icon"
            />
          </Index.Box>
          <Index.Box className="modal add-user-modal-inner-main-wrap">


            {
              roomData === "Admin" ?
                <Index.Box>
                  <Index.Box className="room-list-model-avatar-box" >
                    <Index.Avatar className="room-list-model-avatar"
                      alt={roomData[1]?.winner?.firstName}
                      src={Index.Png.userProfile}
                    />
                  </Index.Box>
                  <Index.Box className="winner-details-modal-admin modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winner name: Computer
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
                : <Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Room id: {roomData[1]?.roomId}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="room-list-model-avatar-box" >
                    <Index.Avatar className="room-list-model-avatar"
                      alt={roomData[1]?.winner?.firstName}
                      src={roomData[1]?.winner?.profileImg != '' ? `http://35.177.56.74:3010/images/${roomData[1]?.winner?.profileImg}` : Index.Png.userProfile}
                    />
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.Box className="winner-color-wraper">

                      {roomData[3]?.winner[0]?.color === "Green" ?
                        <img
                          className="loser-color"

                          alt="Remy Sharp"
                          src={Index.Png.green}
                        /> : roomData[3]?.winner[0]?.color === "Red" ? <img
                          className="room-list-model-avatar"
                          alt="Remy Sharp"
                          src={Index.Png.red}
                        /> : roomData[3]?.winner[0]?.color === "Yellow" ? <img
                          className="room-list-model-avatar"
                          alt="Remy Sharp"
                          src={Index.Png.yellow}
                        /> : <img
                          className="loser-color"
                          alt="Remy Sharp"
                          src={Index.Png.blue}
                        />

                      }


                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winner Name: {roomData[1]?.winner?.firstName}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Played at: {moment(roomData[1]?.createdAt).format("DD-MM-YYYY")}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Bet Amount: {roomData[1]?.betAmount}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Game Type: {roomData[1]?.gameType == "Bot" ? "Computer" : roomData[1]?.gameType}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winning Coins: {roomData[1]?.winningPrice
                      }
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.TableContainer component={Index.Paper}>
                    <Index.Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="main-room-list-modal"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Losers Name</Index.TableCell>
                          <Index.TableCell>Profile</Index.TableCell>
                          <Index.TableCell align="right">color</Index.TableCell>
                          <Index.TableCell align="right">Coins</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {roomData[2]?.loser?.map((row) => {

                          return (
                            <Index.TableRow
                              key={row?.userId?.firstName}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <Index.TableCell component="th" scope="row">
                                {row?.userId?.firstName}
                              </Index.TableCell>
                              <Index.TableCell>
                                <Index.Avatar className="room-list-model-avatar"
                                  alt={row?.userId?.firstName}
                                  src={row?.userId?.profileImg != '' ? `http://35.177.56.74:3010/images/${row?.userId?.profileImg}` : Index.Png.userProfile}
                                />
                              </Index.TableCell>
                              <Index.TableCell align="right">
                                {row?.color === "Green" ?
                                  <img
                                    className="loser-color"

                                    alt="Remy Sharp"
                                    src={Index.Png.green}
                                  /> : row?.color === "Red" ? <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.red}
                                  /> : row?.color === "Yellow" ? <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.yellow}
                                  /> : <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.blue}
                                  />

                                }
                              </Index.TableCell>
                              <Index.TableCell align="right">-{roomData[1]?.betAmount}</Index.TableCell>
                            </Index.TableRow>
                          )
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
            }
          </Index.Box>

        </Index.Box>
      </Index.Modal>
    </>
  );
}
