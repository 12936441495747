import axios from "axios";
// server
const API_ENDPOINT = "http://35.177.56.74:3010/api/";
// local
// const API_ENDPOINT ="http://192.168.29.56:3010/api/"
// const API_ENDPOINT = "http://localhost:3010/api/";


export const DataServices = (token) => {
 
  return axios.create({
    baseURL: API_ENDPOINT,
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      auth:token,
    },
  });
};
