
import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { adminUserChat } from "../../../../redux/admin/action";


const socket = io.connect("http://35.177.56.74:3010/");
export default function UserChat() {
  const adminData = useSelector((state) => state.AdminReducer.Admin);
  const chatData = useSelector((state) => state.AdminReducer.chatData)

  const [adminprofile, setAdminProfile] = useState("");
  const [userprofile, setUserProfile] = useState("");
  const messagesEndRef = useRef(null);
  const [message, setMessage] = useState("");

  const location = useLocation();
  const [chat, setChat] = useState(chatData?.messages);
  const userName = localStorage.getItem("userName")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  let roomId = params?.roomId;
  let userId = params?.userId;

  const adminChat = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("from", adminData?.adminId);
    urlencoded.append("to", userId);
    dispatch(adminUserChat(urlencoded, navigate, userId));
  };
  useEffect(() => {
    adminChat()
  }, [])

  const executeScroll = () => {
    messagesEndRef.current.scrollIntoView();
  }

  const SendMessage = () => {
    if (message != "") {
      let data = {
        from: "admin",
        message: message,
      };
      socket.emit("Message", data);
    }

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    executeScroll();
    setMessage("");
  };
  useEffect(() => {
    adminChat();
    executeScroll();

    setAdminProfile(`http://35.177.56.74:3010/images/${adminData?.profileImg}`);
    if (location?.state?.user?.profileImg) {
      setUserProfile(`http://35.177.56.74:3010/Images/${location?.state?.user?.profileImg}`)
    }
  }, []);
  useEffect(() => {
    let data = {
      roomID: roomId,
    };
    socket.emit("JoinChatRoom", data);
    socket.on("Message", (data) => {
      setChat(data);

    });
  }, [socket]);

  useEffect(() => {
    socket.on("Message", (data) => {
     
      setChat(data);
    });
  }, [message]);

  const backToUser = () => {
    socket.emit("ChatRoomLeave", roomId);
    localStorage.removeItem("userName");
    navigate("/admin/user-list");
  };

 
  return (
    <>
      <Index.Box className="chat-header">
        <Index.Box className="chat-heading">
          <Index.Box className="back-button">
            <Index.Button variant="outlined" onClick={() => backToUser()}>
              <Index.ArrowBackIcon />
            </Index.Button>
          </Index.Box>
          <Index.Typography variant="h6">{userName}</Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="chat-bord">
        <Index.Box class="container " style={{ border: "0.5px solid gray" }}>
          <Index.Box
            style={{ backgroundColor: "#1a243b70", border: "0.5px solid gray" }}
          >
            <Index.Box class="chatbox">
              {chat?.map((row) => {
                
                return (
                  <>
                    {row?.from == "admin" ? (
                      <Index.Box class="chat chat-right-msg">
                        <img
                          src={adminprofile ? adminprofile : Index.Png.usericon}
                          alt=""
                        />
                        <p class="msg">
                          <ion-icon
                            name="caret-back-outline"
                            class="left right-icon"
                          >
                            {" "}
                          </ion-icon>
                          {row.message.split('\n').map((line, index) => (
                       
                       <React.Fragment key={index}>
                         {line}
                         <br />
                       </React.Fragment>
                     ))}
                        </p>
                      </Index.Box>
                    ) : (
                      <div class="chat chat-left-msg">
                        <img
                          src={userprofile != '' ? userprofile : Index.userChatPng}
                          alt=""
                        />
                        <p  class="msg">
                          <ion-icon
                            name="caret-back-outline"
                            class="left right-icon"
                          >
                            {" "}
                          </ion-icon>
              
                         {row.message.split('\n').map((line, index) => (
                       
                       <React.Fragment key={index}>
                         {line}
                         <br />
                       </React.Fragment>
                     ))}
                        </p >
                      </div>
                    )}
                  </>
                );
              })}
              <div ref={messagesEndRef} className="scrolldiv" />
            </Index.Box>
          </Index.Box>
          <Index.Box className="bottom-sent-chat">
            <form onSubmit={() => SendMessage()}>
              <Index.Box className="flex-item">

                <Index.TextField
                  className="chat-input"
                  variant="outlined"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (!e.shiftKey) {
                        e.preventDefault(); // Prevent page reload
                        SendMessage();
                      } else {
                        setMessage(prevMessage => prevMessage.trim() + 'n');
                      }
                    }
                  }}
                  multiline
                // rows={4}
                />
                <Index.Box className="chat-button-wrap">
                <Index.Button
                  variant="contained"
                  className="message-button"
                  type="submit"
                >
                  Send
                </Index.Button>
                </Index.Box>
               

              </Index.Box>
            </form>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
