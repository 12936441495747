import React, {Children, createContext, useContext,useState} from 'react'

export const ImageContext = createContext(null)

const ImageProvider = (props) => {
    const [image,setImage] = useState("");

     const onImageChange = (file)=>{
        setImage(file)
     }
  return (
   <ImageContext.Provider value={{image,onImageChange}}>
    {props?.Children}
   </ImageContext.Provider>
  )
}

export default ImageProvider