import type from "./type";
const initialState = {
  image: "",
};
const ImageReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.onImageChange:
      return {
        ...state,
        image: action?.payload,
      };

    default:
      return state;
  }
};

export default ImageReducer;
