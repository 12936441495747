import React, { useEffect } from "react";
import Index from "../../../Index";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GeneralSetting, getGeneralSetting } from "../../../../redux/admin/action";



const GeneralSettingPage = () => {
  const disptach = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});
  const token = useSelector((state) => state?.AdminReducer?.token);
  const getsettingDetails = useSelector(
    (state) => state?.AdminReducer?.getGeneralSetting
  );

  useEffect(() => {
    if (getsettingDetails && getsettingDetails?.data?.length > 0) {
      setValue("refferalCoin", getsettingDetails?.data[0]?.refferalAmount);
      setValue("minWithdraw",getsettingDetails?.data[0]?.withdrawalminAmount);
      setValue("maxWithdraw",getsettingDetails?.data[0]?.withdrawalmaxAmount);
      setValue("changecoins",getsettingDetails?.data[0]?.coins);
      setValue("rupees", getsettingDetails?.data[0]?.rupees);
      setValue("withdrwalCharges",getsettingDetails?.withdrwalCharges);
      setValue("addCoinCharges",getsettingDetails?.addCoinCharges);
      setValue("botbetAmount", getsettingDetails?.data[0]?.botbetAmount);
      setValue("payementUPIAdress1",getsettingDetails?.data[0]?.payementUPIAdress1);
      setValue("payementUPIAdress2",getsettingDetails?.data[0]?.payementUPIAdress2);
    }
  }, [getsettingDetails]);


  const submit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("refferalAmount", data?.refferalCoin);
    urlencoded.append("withdrawalminAmount", data?.minWithdraw);
    urlencoded.append("withdrawalmaxAmount", data?.maxWithdraw);
    urlencoded.append("changecoins", data?.changecoins);
    urlencoded.append("rupees", data?.rupees);
    urlencoded.append("withdrwalCharges",parseInt(data?.withdrwalCharges));
    urlencoded.append("addCoinCharges",parseInt(data?.addCoinCharges));
    urlencoded.append("botbetAmount", data?.botbetAmount);
    urlencoded.append("payementUPIAdress1", data?.payementUPIAdress1);
    urlencoded.append("payementUPIAdress2", data?.payementUPIAdress2);
    await disptach(GeneralSetting(urlencoded, token));
    
    await reset();
  };
  useEffect(() => {
    disptach(getGeneralSetting(token))
  }, [])


  return (
    <Index.Box>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            General Setting
          </Index.Typography>
        </Index.Box>
        <Index.Box className="tabpanel-main">
          <form className="form-content" onSubmit={handleSubmit(submit)}>
            <Index.Box className="change-pass-main">
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Refferal Coin
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="refferalCoin"
                    {...register("refferalCoin", {
                      required: "Refferal Coin is Required.",
                    })}
                    error={Boolean(errors?.refferalCoin)}
                    helperText={errors?.refferalCoin?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Minimum Withdraw
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="minWithdraw"
                    {...register("minWithdraw", {
                      required: "Minimum Withdraw is Required.",
                    })}
                    error={Boolean(errors?.minWithdraw)}
                    helperText={errors?.minWithdraw?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Maximum Withdraw
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="maxWithdraw"
                    {...register("maxWithdraw", {
                      required: "Maximum Withdraw is Required.",
                    })}
                    error={Boolean(errors?.maxWithdraw)}
                    helperText={errors?.maxWithdraw?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="gen-coin-input">
                <Index.Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Index.Box className="input-box change-pass-input-box">
                  <Index.FormHelperText className="form-lable">
                    Rupees
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      name="rupees"
                      {...register("rupees", {
                        required: "rupees is Required.",
                      })}
                      error={Boolean(errors?.rupees)}
                      helperText={errors?.rupees?.message}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box>=</Index.Box>
                <Index.Box className="input-box change-pass-input-box">
                  <Index.FormHelperText className="form-lable">
                    Coins
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                   <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      name="changecoins"
                      {...register("changecoins", {
                        required: "change coins is Required.",
                      })}
                      error={Boolean(errors?.changecoins)}
                      helperText={errors?.changecoins?.message}
                    />
                  </Index.Box>
                </Index.Box>
                </Index.Box>
              
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Coin Charges (%)
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="charges"
                    
                  {...register("addCoinCharges", {
                    required:"Add coin charges amount is Required.",
                  })}
                  error={Boolean(errors?.addCoinCharges)}
                  helperText={errors?.addCoinCharges?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Withdrawal Charges (%)
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="charges"
                    
                  {...register("withdrwalCharges",{
                    required:"Withdrwal charges amount is Required.",
                  })}
                  error={Boolean(errors?.withdrwalCharges)}
                  helperText={errors?.withdrwalCharges?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                  Computer Bet Amount (Coins)
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="botbetAmount"
                    {...register("botbetAmount", {
                      required: "Bot bet amount is Required.",
                    })}
                    error={Boolean(errors.botbetAmount)}
                    helperText={errors?.botbetAmount?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                Payement UPI Adress 1
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="payementUPIAdress1"
                    {...register("payementUPIAdress1")}
                    // error={Boolean(errors.botbetAmount)}
                    // helperText={errors?.botbetAmount?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box change-pass-input-box">
                <Index.FormHelperText className="form-lable">
                Payement UPI Adress 2
                </Index.FormHelperText>
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    name="payementUPIAdress2"
                    {...register("payementUPIAdress2")}
                    // error={Boolean(errors.botbetAmount)}
                    // helperText={errors?.botbetAmount?.message}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-btn-flex change-pass-flex">
                {/* <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button className="discard-user-btn border-btn">
                      Discard
                    </Index.Button>
                  </Index.Box> */}
                <Index.Box className="save-btn-main border-btn-main">
                  <Index.Button
                    // onClick={onChangePassword}
                    className="save-user-btn border-btn"
                    type="submit"
                  >
                    <img src={Index.Svg.save} className="user-save-icon"></img>
                    Save
                  </Index.Button>

                </Index.Box>
              </Index.Box>
            </Index.Box>
          </form>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default GeneralSettingPage;
