
import Index from "../../../component/Index";
import { Api } from "../../../config/Api";
import { DataServices } from "../../../config/DataService";
import { toast } from "react-toastify";

import ErrorShow from "../../../component/common/ErrorShow";
import { useNavigate } from "react-router-dom";
import { validationSchemaForgotpassword } from "../../../validation/validation";
import { Formik } from "formik";


export default function ForgotPassWord() {
  // const declaration
  const navigate = useNavigate()
  // Initital values declaration
  let initialValues = {
    email: "",
  };

  // functions declaration 
  const handleFormSubmit = (values) => {
    DataServices().post(Api.Admin.forgotPassword, values)
      .then((response) => {

        toast.success(response.data.message);
        navigate("/Otp", { state: response?.data?.data?._id })
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Forgot Password!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to Forgot Password !
                </Index.Typography>
                <Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={
                    validationSchemaForgotpassword
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form
                      // component="form"
                      // spacing={2}
                      // noValidate
                      // autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            autocomplete="off"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Index.Box>

                        <ErrorShow error={errors.email} />

                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          type="submit"
                          className="btn-primary login-btn"
                        >
                          Submit
                        </Index.Button>

                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
