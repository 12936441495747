import React, { useEffect, useState } from "react";
import Index from "../../../component/Index";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AdminLogin } from "../../../redux/admin/action";
import ErrorShow from "../../../component/common/ErrorShow";



export default function Login() {
  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
const [fcmToken,setFcmToken]=useState("")
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [data, setData] = useState({
    user: "",
    password: "",
  });
  const [error, setError] = useState({
    userError: "",
    passwordError: "",
  });
  const onChange = (e) => {
    let { name, value } = e.target;
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };
  const handelSubmit = async () => {

    let isUser = Index.emailCheck(
      data.user,
      "Please enter username",
      "Please enter valid username"
    );
    let isPass = Index.passwordCheck(
      data.password,
      "Please enter password",
      "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    );

    setError((prev) => ({ ...prev, userError: isUser }));
    setError((prev) => ({ ...prev, passwordError: isPass }));
    if (isUser === "" && isPass === "") {
      let token = localStorage.getItem("token");
      const formData = new URLSearchParams();
      formData.append("email", data.user);
      formData.append("password", data.password);
      // formData.append("FCMToken",fcmToken)
      dispatch(AdminLogin(formData, navigate));
    }
  };

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Login!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to Login!
                </Index.Typography>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Email
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      autocomplete="off"
                      name="user"
                      value={data.user}
                      onChange={(e) => onChange(e)}
                    />
                  </Index.Box>
                  <ErrorShow error={error.userError} />
                </Index.Box>
                <Index.Box className="input-box">
                  <Index.FormHelperText className="form-lable">
                    Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.OutlinedInput
                      className="form-control-eye"
                      autocomplete="off"
                      id="outlined-adornment-password"
                      name="password"
                      value={data.password}
                      onChange={(e) => onChange(e)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Index.InputAdornment position="end">
                          <Index.IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Index.VisibilityOff />
                            ) : (
                              <Index.Visibility />
                            )}
                          </Index.IconButton>
                        </Index.InputAdornment>
                      }
                    />
                  </Index.Box>

                  <ErrorShow error={error.passwordError} />
                </Index.Box>
                <Index.Box className="flex-all forgot-row">
                  <Index.Box className="checkbox-main">
                    <Index.FormControlLabel
                      control={<Index.Checkbox />}
                      label="Remember Me"
                      className="checkbox-lable"
                    />
                  </Index.Box>
                  <Index.Link className="text-decoration-none">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="admin-forgot-para common-para"
                    >
                      <Link className="forgot-login" to="forgotpassword">
                        Forgot Password?
                      </Link>
                    </Index.Typography>
                  </Index.Link>
                </Index.Box>
                <Index.Box className="btn-main-primary login-btn-main">
                  <Index.Button
                    type="submit"
                    onClick={handelSubmit}
                    className="btn-primary login-btn"
                  >
                    Login
                  </Index.Button>
           
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
