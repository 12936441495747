import React from "react";
import Index from "../Index";

const ErrorShow = (props) => {
  return (
    <>
      <Index.FormHelperText>
        <p className="error-msg">{props.error ? props.error : ""}</p>
      </Index.FormHelperText>
    </>
  );
};

export default ErrorShow;
