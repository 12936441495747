import React, { useEffect, useState } from "react";
import Index from "../../Index";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Token";
import GavelIcon from "@mui/icons-material/Gavel";
import { Link } from "react-router-dom";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";


export default function Sidebar(props) {
  const [open, setOpen] = React.useState(false);
  const [openTran, setOpenTran] = React.useState(false);
  const [screen, setScreen] = useState("");
  const { pathname } = useLocation();

  const navigate = useNavigate()

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickTran = () => {
    setOpenTran(!openTran);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };



  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  return (
    <>
      <Index.Box
        className={`admin-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <Link to="/admin/dashboard">
              <img
                src={Index.Png.logo}
                className="admin-sidebar-logo"
                alt="logo"
              />
            </Link>
            <Index.Button
                onClick={() => props.setOpen(!props.open)}
                className="sidebar-close-btn"
              >
                <img src={Index.Png.close} className="close-icon" alt="logo" />
              </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <NavLink to="/admin/dashboard" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </NavLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem user-list">
                <NavLink to="/admin/user-list" className="admin-sidebar-link">
                  <img
                    src={Index.Svg.userlist}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  User List
                </NavLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem user-list">
                <NavLink className="admin-sidebar-link" to="/admin/room-list">
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Room List
                </NavLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem user-list">
                <NavLink className="admin-sidebar-link" to="/admin/bet-list">
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Game Bet
                </NavLink>
              </Index.ListItem>
              
              <List
                sx={{ width: "100%", maxWidth: 360, color: "#fff" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="sidebar-inner-nav"
              >
                <ListItemButton onClick={handleClickTran} className="cms-drop">
                  <ListItemIcon sx={{ marginRight: "8px", minWidth: "20px" }}>
                    <InboxIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="transaction" />
                  {openTran ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse
                  in={openTran}
                  timeout="auto"
                  unmountOnExit
                  className="cms-dropdown"
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        pathname === "/admin/withdrawal-list"
                          ? "admin-sidebar-link active"
                          : "dashboard-header"
                      }
                      onClick={() => handleNavigate("/admin/withdrawal-list")}
                    >
                      <ListItemIcon
                        sx={{ marginRight: "8px", minWidth: "20px" }}
                      >
                        <CreditScoreIcon
                          className={
                            pathname === "admin/admin/withdrawal-list"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Withdrawal" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        pathname === "/admin/coin-list"
                          ? "admin-sidebar-link active"
                          : "dashboard-header"
                      }
                      onClick={() => handleNavigate("/admin/coin-list")}
                    >
                      <ListItemIcon
                        sx={{ marginRight: "8px", minWidth: "20px" }}
                      >
                        <MonetizationOnOutlinedIcon
                          className={
                            pathname === "/admin/admin/coin-list"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Coin" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>

              <List
                sx={{ width: "100%", maxWidth: 360, color: "#fff" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="sidebar-inner-nav"
              >
                <ListItemButton onClick={handleClick} className="cms-drop">
                  <ListItemIcon sx={{ marginRight: "8px", minWidth: "20px" }}>
                    <InboxIcon
                      className={
                        pathname === "/admin/userListPage"
                          ? "icon active"
                          : "icon"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="CMS" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className="cms-dropdown"
                >
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        pathname === "/admin/privacy-policy"
                          ? "admin-sidebar-link active"
                          : "dashboard-header"
                      }
                      onClick={() => handleNavigate("/admin/privacy-policy")}
                    >
                      <ListItemIcon
                        sx={{ marginRight: "8px", minWidth: "20px" }}
                      >
                        <InfoIcon
                          className={
                            pathname === "admin/privacy"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Privacy policy" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        pathname === "/admin/terms-and-condition"
                          ? "admin-sidebar-link active"
                          : "dashboard-header"
                      }
                      onClick={() =>
                        handleNavigate("/admin/terms-and-condition")
                      }
                    >
                      <ListItemIcon
                        sx={{ marginRight: "8px", minWidth: "20px" }}
                      >
                        <GavelIcon
                          className={
                            pathname === "/admin/terms-and-condition"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Terms and condition" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        pathname === "/admin/game-rules"
                          ? "admin-sidebar-link active"
                          : "dashboard-header"
                      }
                      onClick={() => handleNavigate("/admin/game-rules")}
                    >
                      <ListItemIcon
                        sx={{ marginRight: "8px", minWidth: "20px" }}
                      >
                        <SportsEsportsIcon
                          className={
                            pathname === "/admin/game-rules"
                              ? "icon active"
                              : "icon"
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary="Game Rules" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
