import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import Loading from "../../../../component/common/loading/Loading";
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};


export default function UserDetails() {
  const token = useSelector((state) => state?.AdminReducer?.token);
const navigate=useNavigate()
const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [userPerPage, setUserPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [select, setSelect] = React.useState("total");
  const [loading, setLoading] = useState(true)
  const [roomData, setRoomData] = useState({})
  const [roomOpen, setRoomOpen] = useState(false)
  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = filterData.slice(indexOfFirstUser, indexOfLastUser);
  const [transactionOpen, setTransactionOpen] = useState(false)

  const handleChange = (event) => {
    setSelect(event.target.value);
  };
  const handleRoomOpen = (row) => {
    setRoomOpen(true)
    // setRowData(row)

  };
  const handleTransaction = () => {
    setTransactionOpen(!transactionOpen?true:false)

  }
  const handleWithdrawal=(data)=>{
    navigate("/admin/user-transaction-history" ,{state:{Status:`${data}`,id:id}})
  }
  
  const handleRoomClose = () => setRoomOpen(false);
  const getRoomDetails = async (id) => {
    const formData = new URLSearchParams();
    formData.append("roomId", id);

    await DataServices(token)
      .post(Api.Admin.getRoomByRoomId, formData)
      .then((response) => {
        setRoomData(response?.data?.data);
      });
  };


  const handlePageChange = (event, value) => {
    const val = Number(value);
    setCurrentPage(val);
  };

  const getAllUsers = async () => {
    const formData = new URLSearchParams();
    if (select === "last30days") {
      formData.append("last30days", select);
    }
    formData.append("id", id);
    await DataServices(token)
      .post(Api.Admin.getRoomsByUserId, formData)
      .then((response) => {
        if (response?.status == 200) {
          setUserList(response?.data?.data);
          setFilterData(response?.data?.data);
          setUserData(response?.data);
          setLoading(false)
        }
      });
  };
  const onsearch = async (e) => {
    setSearchValue(e.target.value);
    const filteredUsers = userList?.filter((item) => {
      const roomId = item?.roomId?.toLowerCase()
        .includes(e.target.value.toLowerCase());
      const playedWith = item?.playedWith?.toLowerCase()
        .includes(e.target.value.toLowerCase());
      // const Amount = item?.Amount?.includes(
      //   e.target.value
      // );
      // const isWinner =item?.isWinner
      //   .toLowerCase()
      //   .includes(e.target.value.toLowerCase());
      return roomId || playedWith
    });
    setFilterData(filteredUsers);
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(userList?.length / userPerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    getAllUsers();
  }, [select]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            User Details List
          </Index.Typography>
          <Index.Box className="user-list-header-transaction-wrap">
            {
              transactionOpen && <Index.Typography
                className="total-coins-heading withdrawal-heading"
                component="h6"
                variant="h6"
                sx={{ cursor: "pointer", color: "#ff9265" }}
                onClick={()=>handleWithdrawal("Withdrawal")}
              >
                Withdrawal
              </Index.Typography>
            }
            <Index.Typography
              className="total-coins-heading transaction-heading"
              component="h6"
              variant="h6"
              sx={{ cursor: "pointer" }}
              onClick={handleTransaction}
            >
              Transaction
            </Index.Typography>
            {
              transactionOpen && <Index.Typography
                className="total-coins-heading withdrawal-heading"
                component="h6"
                variant="h6"
                sx={{ cursor: "pointer", color: "#ff9265" }}
                onClick={()=>handleWithdrawal("Coin")}
              >
                Coin
              </Index.Typography>
            }
          </Index.Box>

          <Index.Typography
            className="total-coins-heading"
            component="h6"
            variant="h6"
          >
            Remaining Coins = <span className="total-coins">{userData?.checkTotalCoins}</span>
          </Index.Typography>
        </Index.Box>
        <Index.Box className="user-detail-card-main">
          <Index.Box className="user-detail-card">
            <Index.Typography
              component="p"
              variant="p"
              className="card-head-detail"
            >
              Win-lose Ratio Till Date
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Total Game <span>{userData?.totalGames}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Win/Loss{" "}
              <span>
                {userData?.totalWins}/{userData?.totalLoses}
              </span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Win Ratio <span>{userData?.winRatio}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Loss Ratio <span>{userData?.lossratio}</span>
            </Index.Typography>
          </Index.Box>
          <Index.Box className="user-detail-card">
            <Index.Typography
              component="p"
              variant="p"
              className="card-head-detail"
            >
              Today's Win-lose Ratio
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Total Game <span>{userData?.todayGames}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Win/Loss{" "}
              <span>
                {userData?.todayWins}/{userData?.todayLoss}
              </span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Win Ratio <span>{userData?.todayWinratio}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Loss Ratio<span>{userData?.todaylossratio}</span>
            </Index.Typography>
          </Index.Box>
          <Index.Box className="user-detail-card">
            <Index.Typography
              component="p"
              variant="p"
              className="card-head-detail"
            >
              Total Winning Coins
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Referrals Bonus{" "}
              <span>{userData?.EarnedthroughReferrals}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Winning Coins<span>{userData?.TotalEarnedCoins}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Daily Rewards Bonus<span>{userData?.TotalDailyRewardCoin}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Total Purchased Coin
              <span>{userData?.BalancefromMoneyPaid}</span>
            </Index.Typography>
            <Index.Typography
              component="p"
              variant="p"
              className="card-body-detail"
            >
              Spend Coin
              <span>{userData?.SpendCoins}</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="user-detail-table-main">
          <Index.Box className="user-detail-table-card">
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="card-head-table"
                >
                  Total Game Played
                </Index.Typography>
                <Index.Box className="user-detail-search">
                  <FormControl
                    sx={{ m: 1, minWidth: 100 }}
                    className="user-detail-formcontrol"
                  >
                    <Select
                      value={select}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      className="user-detail-select"
                    >
                      <MenuItem value="total">All</MenuItem>
                      <MenuItem value="last30days">Last 30 Days</MenuItem>
                    </Select>
                  </FormControl>
                  <Index.Box className="userlist-btn-flex">
                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            value={searchValue}
                            onChange={onsearch}
                            placeholder="Search user"
                          />

                          <img
                            src={Index.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="userlist-table-main page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  ROOMID
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  GAME TYPE
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  STATUS
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  AMOUNT
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  PLAYED AT
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loading ? currentUsers.length ? (
                                currentUsers?.map((row, index) => (
                                  <Index.TableRow
                                    key={row.name}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td-click"
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        handleRoomOpen();
                                        getRoomDetails(row?.roomId);
                                      }}
                                    >
                                      {row?.roomId}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.playedWith == "Bot" ? "Computer" : row?.playedWith}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.isWinner ? "win" : "lose"}

                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      style={{
                                        color:
                                          row?.Amount >= 0 ? "green" : "red",
                                      }}
                                    >
                                      {row?.Amount}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {moment(row?.PlayedAt).format("lll")}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              ) : <NoRecordFound colSpan={12} /> : <Loading colSpan={12} />}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          count={pageNumbers?.length}
                          onChange={handlePageChange}
                          variant="outlined"
                          shape="rounded"
                          className="pagination"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={roomOpen}
        onClose={handleRoomClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >

        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">

          <Index.Box className="modal-circle-main" onClick={handleRoomClose}>
            <img
              src={Index.Svg.closecircle}
              className="user-circle-img"
              alt="icon"
            />
          </Index.Box>
          <Index.Box className="modal add-user-modal-inner-main-wrap">


            {
              roomData === "Admin" ?
                <Index.Box>
                  <Index.Box className="room-list-model-avatar-box" >
                    <Index.Avatar className="room-list-model-avatar"
                      alt={roomData[1]?.winner?.firstName}
                      src={Index.Png.userProfile}
                    />
                  </Index.Box>
                  <Index.Box className="winner-details-modal-admin modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winner name: Computer
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
                : <Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Room id: {roomData[1]?.roomId}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="room-list-model-avatar-box" >
                    <Index.Avatar className="room-list-model-avatar"
                      alt={roomData[1]?.winner?.firstName}
                      src={roomData[1]?.winner?.profileImg != '' ? `http://35.177.56.74:3010/images/${roomData[1]?.winner?.profileImg}` : Index.Png.userProfile}
                    />
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.Box className="winner-color-wraper">

                      {roomData[3]?.winner[0]?.color === "Green" ?
                        <img
                          className="loser-color"

                          alt="Remy Sharp"
                          src={Index.Png.green}
                        /> : roomData[3]?.winner[0]?.color === "Red" ? <img
                          className="room-list-model-avatar"
                          alt="Remy Sharp"
                          src={Index.Png.red}
                        /> : roomData[3]?.winner[0]?.color === "Yellow" ? <img
                          className="room-list-model-avatar"
                          alt="Remy Sharp"
                          src={Index.Png.yellow}
                        /> : <img
                          className="loser-color"
                          alt="Remy Sharp"
                          src={Index.Png.blue}
                        />

                      }


                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winner Name: {roomData[1]?.winner?.firstName}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Played at: {moment(roomData[1]?.createdAt).format("DD-MM-YYYY")}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Bet Amount: {roomData[1]?.betAmount}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Game Type: {roomData[1]?.gameType == "Bot" ? "Computer" : roomData[1]?.gameType}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="winner-details-modal modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winning Coins: {roomData[1]?.winningPrice
                      }
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.TableContainer component={Index.Paper}>
                    <Index.Table
                      sx={{ minWidth: 650 }}
                      aria-label="simple table"
                      className="main-room-list-modal"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Losers Name</Index.TableCell>
                          <Index.TableCell>Profile</Index.TableCell>
                          <Index.TableCell align="right">color</Index.TableCell>
                          <Index.TableCell align="right">Coins</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {roomData[2]?.loser?.map((row) => {

                          return (
                            <Index.TableRow
                              key={row?.userId?.firstName}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <Index.TableCell component="th" scope="row">
                                {row?.userId?.firstName}
                              </Index.TableCell>
                              <Index.TableCell>
                                <Index.Avatar className="room-list-model-avatar"
                                  alt={row?.userId?.firstName}
                                  src={row?.userId?.profileImg != '' ? `http://35.177.56.74:3010/images/${row?.userId?.profileImg}` : Index.Png.userProfile}
                                />
                              </Index.TableCell>
                              <Index.TableCell align="right">
                                {row?.color === "Green" ?
                                  <img
                                    className="loser-color"

                                    alt="Remy Sharp"
                                    src={Index.Png.green}
                                  /> : row?.color === "Red" ? <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.red}
                                  /> : row?.color === "Yellow" ? <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.yellow}
                                  /> : <img
                                    className="loser-color"
                                    alt="Remy Sharp"
                                    src={Index.Png.blue}
                                  />

                                }
                              </Index.TableCell>
                              <Index.TableCell align="right">-{roomData[1]?.betAmount}</Index.TableCell>
                            </Index.TableRow>
                          )
                        })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
            }
          </Index.Box>

        </Index.Box>
      </Index.Modal>
    </>
  );
}
