import React, { useEffect } from "react";
import Index from "../../../Index";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addBet, getBetList } from "../../../../redux/admin/action";
import { useState } from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddBetPage = ({ rowData }) => {
  const disptach = useDispatch();
  const [open, setOpen] = useState(false)


  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  const token = useSelector((state) => state?.AdminReducer?.token);
  const AdminDetails = useSelector((state) => state?.AdminReducer?.Admin);

  useEffect(() => {
    setValue("betName", rowData ? rowData.betName : "")
    setValue("betAmount", rowData?.betAmount)
    setValue("winningPrice", rowData?.WinnerPrice)
    setValue("players", rowData?.players)
  }, [rowData])

  const submit = (data) => {
    const urlencoded = new URLSearchParams()
    urlencoded.append("id", AdminDetails?._id);
    urlencoded.append("betName", data?.betName);
    urlencoded.append("betAmount", data?.betAmount);
    urlencoded.append("WinnerPrice", data?.winningPrice);
    urlencoded.append("players", data?.players);
    rowData && urlencoded.append("oldBetId", rowData?._id);
    disptach(addBet(urlencoded, token))
    handleClose();

  };

  const handleClose = () => {
    if (!rowData) {
      setOpen(false)
      setValue("betName", "")
      setValue("betAmount", "")
      setValue("winningPrice", "")
      setValue("players", "")
    } else {

      setOpen(false)
      setValue("betName", rowData?.betName)
      setValue("betAmount", rowData?.betAmount)
      setValue("winningPrice", rowData?.WinnerPrice)
      setValue("players", rowData?.players)
    }
  }
  return (
    <>
      {!rowData ? <Index.Button
        className="adduser-btn btn-primary"
        onClick={() => {
          setOpen(true);
          // navigate("/admin/bet-list/add");
        }}
      >
        <img
          src={Index.Svg.plus}
          className="plus-icon"
          alt="plus icon"
        />
        Add Bet
      </Index.Button> :
        <Index.IconButton>
          <Index.EditIcon
            onClick={() => setOpen(true)}
            color="warning"
          />
        </Index.IconButton>}
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          <Index.Box className="dashboard-content">
            <Index.Box className="user-list-flex">
              <Index.Typography
                className="admin-page-title user-list-page-title"
                component="h6"
                variant="h6"
              >
                {!rowData ? "Add Bet" : "Edit Bet"}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="tabpanel-main">
              <form className="form-content" onSubmit={handleSubmit(submit)}>
                <Index.Box className="change-pass-main">
                  <Index.Box className="input-box change-pass-input-box">
                    <Index.FormHelperText className="form-lable">
                      Bet Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="betName"
                        inputProps={{ maxLength:20 }} // Set a character limit of 50
                        {...register("betName", {
                          required: "Bet Name is required.",
                          maxLength: { value:20, message:"Bet Name cannot exceed 20 characters." },
                        })}
                        error={Boolean(errors?.betName)}
                        helperText={errors?.betName?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box change-pass-input-box">
                    <Index.FormHelperText className="form-lable">
                      Bet Amount
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        name="betAmount"
                   
                        {...register("betAmount", {
                          required: "Bet Amount is Required.",
                          maxLength: { value:20, message:"Bet Amount cannot exceed 20 digits." },
                        })}
                        error={Boolean(errors?.betAmount)}
                        helperText={errors?.betAmount?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box change-pass-input-box">
                    <Index.FormHelperText className="form-lable">
                      Winning Price
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        name="winningPrice"
                        inputProps={{ maxLength:20 }}
                        {...register("winningPrice", {
                          required: "Winning Price is Required.",
                          maxLength: { value:20, message:"Winning Price cannot exceed 20 digits." },
                        })}
                        error={Boolean(errors?.winningPrice)}
                        helperText={errors?.winningPrice?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box change-pass-input-box">
                    <Index.FormHelperText className="form-lable">
                    Players
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        type="number"
                        className="form-control"
                        name="players"     
                        {...register("players", {
                          required: "Players is required.",
                          pattern: {
                            value: /^[2-4]$/, // Match only single digits from 2 to 4
                            message: "Please enter a number 2,3 and 4.",
                          },
                        })}
                        error={Boolean(errors?.players)}
                        helperText={errors?.players?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                
                  <Index.Box className="user-btn-flex change-pass-flex">
                    <Index.Box className="discard-btn-main border-btn-main">
                      <Index.Button className="discard-user-btn border-btn" onClick={handleClose}>
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                      >
                        <img src={Index.Svg.save} className="user-save-icon"></img>
                        Save
                      </Index.Button>

                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AddBetPage;
