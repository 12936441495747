import axios from "axios";
// server
const BASE_URL = "http://35.177.56.74:3010/api";
// local
// const BASE_URL ="http://192.168.29.56:3010/api"

{
  // console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


export const setHeadersWithAccessToken = (token) => {
  axios.defaults.headers.post["auth"] = token;
};

const setHeadersWithContentType = () => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
};

const setGetHeadersWithAccessToken = (token) => {

  axios.defaults.headers.get["auth"] = token;
};


const handleError = (error) => {
  //console.log(error,'db-error');
  for (const key in error) {
    if (Object.hasOwnProperty.call(error, key)) {
      const element = error["response"];
      //console.log(element,4444);
      return { message: element?.data?.message, status: element?.status };
    }
  }
};



export const loginApi = (params) => {
  setHeadersWithContentType();
  return axios
  .post(`${BASE_URL}/admin/login`, params)
  .then((e) => e.data)
  .catch((e) => handleError(e));
}

export const editProfileApi = (params , token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
  .post(`${BASE_URL}/admin/profile-update`, params)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}

export const GeneralSettingApi = (params,token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
  .post(`${BASE_URL}/admin/generalSetting`, params)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}

export const getGeneralSettingApi = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
  .get(`${BASE_URL}/admin/getGeneralSetting`)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}


export const getBetListApi = (token) => {
  setHeadersWithContentType();
  setGetHeadersWithAccessToken(token);
  return axios
  .get(`${BASE_URL}/admin/allBets`)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}

export const addBetApi = (params , token) => {
  setHeadersWithContentType();
  setHeadersWithAccessToken(token);
  return axios
  .post(`${BASE_URL}/admin/add-editBet`, params)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}

export const adminUserChatApi = (params) => {
  // setGetHeadersWithAccessToken(token)
  return axios
  .post(`${BASE_URL}/admin/chatroom` , params)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}
export const notificationApi = (token) => {
  setGetHeadersWithAccessToken(token)
  return axios
  .get(`${BASE_URL}/admin/getNotification`)
  .then((e) => e?.data)
  .catch((e) => handleError(e));
}