import React from "react";
import PropTypes from "prop-types";
import Index from "../../../../component/Index";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import { toast } from "react-toastify";

import { ImageChange } from "../../../../redux/image/action";
import { EditProfile } from "../../../../redux/admin/action";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditProfilePage() {
  const adminDetails = useSelector((state) => state.AdminReducer.Admin);
  const token = useSelector((state) => state.AdminReducer.token);

  const dispatch = useDispatch();
  // const adminDetails = JSON.parse(localStorage.getItem("admin-details"));


  const [value, setValue] = useState(0);

  const [image, setImage] = useState();
  const [data, setData] = useState({
    name: adminDetails?.name,
    mobNumber: adminDetails?.mobNumber,
    oldpassword: "",
    password: "",
    confirmPassword: "",
   
  });
  const [error, setError] = useState({
    nameError: "",
    mobNumberError: "",
    oldpasswordError: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  const onSamePassword = (condition) => {
    const errorMessage = "Password must be same";
    if (!condition) {
      // const errorMessage = "Password must be same";
      setError({
        ...error,
        confirmPasswordError: errorMessage,
        // passwordError:  errorMessage ,
      });
    } else {
      setError({
        ...error,
        confirmPasswordError: "",
        // passwordError: "",
      });
    }
  };

  const onPasswordChange = (e) => {
    onSamePassword(data?.password === data?.confirmPassword);
    let { name, value } = e.target;

    if (name === "oldpassword") {
      if (value.length > 0) {
        setError({
          ...error,
          oldpasswordError: "",
        });
      }
    }

    if (name === "password") {
      if (value.length > 0) {
        setError({
          ...error,
          passwordError: "",
        });
      }
    }
    if (name === "confirmPassword") {
      if (value.length > 0) {
        onSamePassword(data?.password === value);
      }
    }
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const onChange = (e) => {
    let { name, value } = e.target;
    setData((data) => ({
      ...data,
      [name]: name === "mobNumber" ? value.slice(0, 10) : value,
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onChangePassword = async () => {
    let isOldpassword = Index.valueCheck(
      data.oldpassword,
      "Please enter password"
    );

    let isPassword = Index.passwordCheck(
      data.password,
      "Please enter new password",
      "Minimum eight characters,at least one uppercase letter, one lowercase letter,one number and one special character"
    );
    let isConfirmPassword = Index.confirmPasswordCheck(
      data.confirmPassword,
      data.password,
      "Please enter password",
      "Minimum eight characters,at least one uppercase letter, one lowercase letter,one number and one special character",
      "Both passwords are not same"
    );

    setError((prev) => ({ ...prev, oldpasswordError: isOldpassword }));
    setError((prev) => ({ ...prev, passwordError: isPassword }));
    setError((prev) => ({ ...prev, confirmPasswordError: isConfirmPassword }));

    if (
      !data.oldpassword ||
      !data.password ||
      !data.confirmPassword ||
      data.password !== data.confirmPassword
    ) {
      return;
    }

    const formData = new URLSearchParams();
    formData.append("oldPassword", data?.oldpassword);
    formData.append("newPassword", data?.password);
    DataServices(token)
      .post(Api.Admin.changePassword, formData)
      .then((response) => {
        toast.success(response?.data?.message);
        setTimeout(() => { }, 2000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };
  const onEditProfile = async () => {
    let isName = Index.valueCheck(data.name, "Please enter Name");
    let isMobNumber = Index.numberCheck(
      data.mobNumber,
      "Please enter Mobile Number"
    );
    let isOldpassword = Index.valueCheck(
      data.oldpassword,
      "Please enter Old password"
    );

    let isPassword = Index.passwordCheck(
      data.password,
      "Please enter new password",
      "Minimum eight characters,at least one uppercase letter, one lowercase letter,one number and one special character"
    );
    let isConfirmPassword = Index.confirmPasswordCheck(
      data.confirmPassword,
      data.password,
      "Please Confirm password",
      // "Minimum eight characters,at least one uppercase letter, one lowercase letter,one number and one special character",
      "Both passwords are not same"
    );

    setError((prev) => ({ ...prev, oldpasswordError: isOldpassword }));
    setError((prev) => ({ ...prev, passwordError: isPassword }));
    setError((prev) => ({ ...prev, confirmPasswordError: isConfirmPassword }));
    setError((prev) => ({ ...prev, nameError: isName }));
    setError((prev) => ({
      ...prev,
      mobNumberError: isMobNumber,
    }));
    if (!data?.name || !data?.mobNumber) {
      return;
    }

    const urlencoded = new URLSearchParams();
    urlencoded.append("name", data?.name);
    urlencoded.append("mobNumber", data?.mobNumber);
    dispatch(EditProfile(urlencoded, token));
  };

  const getAdminProfile = async () => {
    const formData = new URLSearchParams();
    DataServices(token)
      .get(Api.Admin.getAdminProfile, formData)
      .then((response) => {
        // setImage(response?.data?.data?.profileImg);
        // const images =
        //   process.env.REACT_APP_IMAGE_BASE_URL + response.data.data.profileImg;
        // setImage(images);
      });
  };

  useEffect(() => {
    if (token != "") {
      getAdminProfile();
    }
  }, [token]);

  const uploadImage = async (e) => {
    dispatch(ImageChange(URL.createObjectURL(e.target.files[0])));

    setImage(URL.createObjectURL(e.target.files[0]));
    const formData = new FormData();
    formData.append("profileImg", e.target.files[0]);
    await DataServices(token)
      .post(Api.Admin.profileImg, formData)
      .then((res) => {
        localStorage.setItem("profile-image", image);
      });
 
  };


  return (
    <Index.Box className="dashboard-content edit-profile-containt">
      <Index.Typography
        className="admin-page-title"
        component="h2"
        variant="h2"
      >
        Account Settings
      </Index.Typography>

      <Index.Box className="tabs-main-box">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab
                label="Edit Profile"
                {...a11yProps(0)}
                className="admin-tab"
              />
              <Index.Tab
                label="Change Password"
                {...a11yProps(1)}
                className="admin-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={value} index={0} className="admin-tabpanel">
            <Index.Box className="tabpanel-main">
              <Index.Box className="page-border">
                <Index.Typography
                  className="common-para edit-highlight-text"
                  component="p"
                  variant="p"
                >
                  General
                </Index.Typography>
                <Index.Typography
                  className="common-para edit-para-text"
                  component="p"
                  variant="p"
                >
                  Basic info, like your name and Mobile No that will be
                  displayed in public
                </Index.Typography>
                <Index.Box className="edit-profile-flex">
                  <Index.Box className="file-upload-btn-main">
                    <img
                      // src={Index.Png.usericon}
                      // src={`http://35.177.56.74:3010/images/${image}`}
                      src={
                        image?image:adminDetails?.profileImg!== ""
                            ?`http://35.177.56.74:3010/images/${adminDetails?.profileImg}`
                            :Index.Png.userProfile
                      }
                      className="upload-profile-img"
                      alt="upload img"
                    ></img>
                    <Index.Button
                      variant="contained"
                      component="label"
                      className="file-upload-btn"
                    >
                      <img
                        src={Index.Svg.edit}
                        className="upload-icon-img"
                        alt="upload img"
                      ></img>
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          uploadImage(e);
                        }}
                      />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder=""
                              name="name"
                              value={data?.name}
                              onChange={(e) => onChange(e)}
                            />
                          </Index.Box>
                          <Index.FormHelperText>
                            <p className="error-msg ">{error?.nameError}</p>
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Mobile no
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              type={"number"}
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              name="mobNumber"
                              value={data?.mobNumber}
                              onChange={(e) => onChange(e)}
                            />
                          </Index.Box>
                          <Index.FormHelperText>
                            <p className="error-msg ">{error?.mobNumberError}</p>
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      ></Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      ></Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex">
                          <Index.Box className="discard-btn-main border-btn-main">
                            {/* <Index.Button className="discard-user-btn border-btn">
                              Discard
                            </Index.Button> */}
                          </Index.Box>
                          <Index.Box className="save-btn-main border-btn-main">
                            <Index.Button
                              className="save-user-btn border-btn"
                              onClick={onEditProfile}
                            >
                              <img
                                src={Index.Svg.save}
                                className="user-save-icon"
                              ></img>
                              Save
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={1} className="admin-tabpanel">
            <Index.Box className="tabpanel-main">
              <Index.Box className="change-pass-main">
                <Index.Typography
                  className="common-para edit-highlight-text"
                  component="p"
                  variant="p"
                >
                  Change Password
                </Index.Typography>
                <Index.Typography
                  className="common-para edit-para-text"
                  component="p"
                  variant="p"
                >
                  Enter your current & new password to change your password
                </Index.Typography>
                <Index.Box className="input-box change-pass-input-box">
                  <Index.FormHelperText className="form-lable">
                    Old Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      onChange={onPasswordChange}
                      value={data?.oldpassword}
                      name="oldpassword"
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    />
                  </Index.Box>
                  <Index.FormHelperText>
                    <p className="error-msg ">{error?.oldpasswordError}</p>
                  </Index.FormHelperText>
                </Index.Box>
                <Index.Box className="input-box change-pass-input-box">
                  <Index.FormHelperText className="form-lable">
                    New Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="password"
                      onChange={onPasswordChange}
                      value={data?.password}
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    />
                  </Index.Box>
                  <Index.FormHelperText>
                    <p className="error-msg ">{error?.passwordError}</p>
                  </Index.FormHelperText>
                </Index.Box>
                <Index.Box className="input-box change-pass-input-box">
                  <Index.FormHelperText className="form-lable">
                    Confirm Password
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      name="confirmPassword"
                      value={data?.confirmPassword}
                      onChange={onPasswordChange}
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder=""
                    />
                  </Index.Box>
                  <Index.FormHelperText>
                    <p className="error-msg ">{error?.confirmPasswordError}</p>
                  </Index.FormHelperText>
                </Index.Box>
                <Index.Box className="user-btn-flex change-pass-flex">
                  {/* <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button className="discard-user-btn border-btn">
                      Discard
                    </Index.Button>
                  </Index.Box> */}
                  <Index.Box className="save-btn-main border-btn-main">
                    <Index.Button
                      onClick={onChangePassword}
                      className="save-user-btn border-btn"
                    >
                      <img
                        src={Index.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>


    </Index.Box>
  );
}
