import React from 'react'
import Index from '../../Index'

const NoRecordFound = (props) => {
    return (
        <Index.TableRow>
            <Index.TableCell colSpan={props.colSpan} >
                <Index.Typography className="table-data-not-found">RECORD NOT FOUND</Index.Typography>
            </Index.TableCell>
        </Index.TableRow>
    )
}

export default NoRecordFound