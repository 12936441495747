import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";

export default function PrivacyPolicy() {

  const token = useSelector((state) => state?.AdminReducer?.token);

  const [description, setDescription] = useState("");


  const handelSubmit = async () => {

   
    if (description !== "") {
      const formData = new URLSearchParams();
      formData.append("description", description);

      DataServices(token)
        .post(Api.Admin.privacyPolicy, formData)
        .then((response) => {
          toast.success(response?.data?.message);
          setTimeout(() => {}, 1500);
        });
    }
  };

  const getCMS = async (id) => {


    const formData = new URLSearchParams();
    await DataServices(token)
      .get(Api.Admin.getCMS, formData)
      .then((res) => {
        setDescription(res?.data?.data?.privacyPolicy?.description);
      });
  };

  useEffect(() => {
    getCMS();
  }, []);

  const handleEditorChange = (event) => {
    const data = event.editor.getData();
    setDescription(data);
  };

  return (
    <>
      <Index.Box className="page-bg-2">
        <Index.Box className="nft-box">
          <Index.Box className="nftpage-main">
            <Index.Box className="admin-login-inner">
              
              <Index.Typography
                component="h2"
                variant="h2"
                className="admin-sign-para title-dash"
              >
                Privacy Policy
              </Index.Typography>
              <Index.Box className="card-Token-main">
              {/* <Index.FormHelperText className="form-lable">
                  <h3>Title</h3>
                </Index.FormHelperText> */}
                {/* <Index.Box className="dash dash-privacy">
                  
                  <TextField
                    className="privacybox dash-page-input"
                    id="outlined-basic"
                    // label="Title"
                    variant="outlined"
                    name="title"
                    value={title}
                    onChange={onChange}
                  />
                  <Index.FormHelperText>
                    <p className="error-msg ">{error.titleError}</p>
                  </Index.FormHelperText>
                </Index.Box> */}
                <Index.FormHelperText>
                  <h3>Description</h3>
                </Index.FormHelperText>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
                <div className="privacy-policy-button-main">
                  <Index.Button
                    onClick={handelSubmit}
                    className="submit-privacy"
                  >
                    Update
                  </Index.Button>
                 
                </div>
        
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
