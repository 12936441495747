import {
  Box,
  Badge,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
} from "@mui/material";
import {
      emailCheck,
      passwordCheck,
      valueCheck,
      confirmPasswordCheck,
      linkCheck,  
      numberCheck,
      otpCheck,
      number,
    } from "../container/admin/auth/AuthValidation";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Sidebar from "../component/admin/defaulLayout/Sidebar";
import Header from "../component/admin/defaulLayout/Header";
import PaidLable from "./common/PaidLable";
import FailedLable from "./common/FailedLable";
import PendingLable from "./common/PendingLable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../component/common/PrimaryButton.js";
import { Link } from "react-router-dom";

export default {
      emailCheck,
      passwordCheck,
      valueCheck,
      confirmPasswordCheck,
      linkCheck,  
      numberCheck,
      otpCheck,
      number,
  Box,
  Badge,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
  PrimaryButton,
};
