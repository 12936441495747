const ADMIN = "admin";

export const Api = {
  Admin: {
    loginAdmin: `${ADMIN}/login`,
    forgotPassword: `${ADMIN}/forgot-password`,
    resetPassword: `${ADMIN}/reset-password`,
    resendOtpData: `${ADMIN}/resend-otp`,
    changePassword: `${ADMIN}/change-password`,
    OtpVerify: `${ADMIN}/otp-verify`,
    getAdminProfile: `${ADMIN}/getprofile`,
    updateProfile: `${ADMIN}/profile-update`,
    getAllUsers: `${ADMIN}/users`,
    getCMS:`${ADMIN}/cms`,
    privacyPolicy: `${ADMIN}/privacyPolicy`,
    termsAndCondition: `${ADMIN}/termsAndCondition`,
    activeandInactiveuser: `${ADMIN}/deactivate-user`,
    profileImg: `${ADMIN}/add-profileImg`,
    gamesRules:  `${ADMIN}/gamesRules`,
    showonlineusers: `${ADMIN}/onlineUsers`,
    showrefrllUser:  `${ADMIN}/showRefrelUsers`,
    editUser: `${ADMIN}/edit-user`,
    getAllroom: `${ADMIN}/allRoom`,
    getsinglerooominformation:  `${ADMIN}/roomId`,
    getCoinList:`${ADMIN}/getpendingCoins`,
    getWithdrawalList:`${ADMIN}/getPendingWithdrwal`,
    acceptAddCoins:`${ADMIN}/acceptAddCoins`,
    acceptWithdrawl:`${ADMIN}/acceptWithdrawl`,
    activeRooms:`${ADMIN}/activeRooms`,
    allRooms:`${ADMIN}/allRooms`,
    getRoomsByUserId:`${ADMIN}/get-roomsByUserId`,
    getRoomByRoomId:`${ADMIN}/get-roomByRoomId`,
    allTransaction:`${ADMIN}/allTransaction`,
    accpetOrRejectTransaction:`${ADMIN}/accpetOrRejectTransaction`,
    activeAndInactiveGameBet:`${ADMIN}/active-DeactiveBet`,
    userWithdrawalhistoryList:`${ADMIN}/single-user-transactionHistory`,
    removeNotification:`${ADMIN}/single-NotificationById`
  },
};
