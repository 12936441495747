import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { useDispatch, useSelector } from "react-redux";
import { getBetList } from "../../../../redux/admin/action";
import AddBetPage from "./AddBet";
import { Modal } from "@mui/material";
import moment from "moment/moment";
import Loading from "../../../../component/common/loading/Loading";
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";
import { DataServices } from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { setHeadersWithAccessToken } from "../../../../services/AdminIndex";
import { toast } from "react-toastify";
const label = { inputProps: { "aria-label": "Switch demo" } };


export default function BetList() {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.AdminReducer?.token);
  const { BetList, loading } = useSelector((state) => state?.AdminReducer);


  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePageChange = (event, value) => {
    const val = Number(value);
    setCurrentPage(val);
  };

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = filterData?.length ?filterData?.slice(indexOfFirstUser, indexOfLastUser)
    : BetList?.slice(indexOfFirstUser, indexOfLastUser);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filterData?.length ?filterData?.length / userPerPage:BetList?.length / userPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    dispatch(getBetList(token));

  }, []);

  

  const onsearch = async (e) => {
    setSearchValue(e.target.value);
    const filteredUsers = BetList.filter((item) => {
      const betName = item?.betName
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase());
      const betId = item?.betId
        ?.toString()
        .includes(e.target.value?.toString());
      return betName || betId;
    });
    setFilterData(filteredUsers);
  };

  const checkData = (data) => {
    return data ? data : "-";
  };
  const handleSwitchChange = async (id, Status) => {
    const formData = new URLSearchParams();
    formData.append("oldBetId", id);
    formData.append("status", Status);
    await DataServices(token).post(
      Api.Admin.activeAndInactiveGameBet, formData)
      .then((res) => {
        if (res?.status === 200) {
          res?.data?.data?.Status=="Active"?
          toast.success("Game bet details activated"):
          toast.success("Game bet details deactivated")
          dispatch(getBetList(token));
        }
      })
  }

  return (
    <>
      <Index.Box className="dashboard-content">

        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Game Bet
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box flex-search-btn">
                <Index.Box className="form-group">
                  <Index.Box className="flex-all user-list-inner-flex">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={searchValue}
                      onChange={(e) => onsearch(e)}
                      placeholder="Search"
                    />
                    <img
                      src={Index.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="adduser-btn-main btn-main-primary">
                  {/* <Index.Button
                    className="adduser-btn btn-primary"
                    onClick={() => {
                      handleOpen();
                      // navigate("/admin/bet-list/add");
                    }}
                  >
                    <img
                      src={Index.Svg.plus}
                      className="plus-icon"
                      alt="plus icon"
                    />
                    Add Bet
                  </Index.Button> */}
                  <AddBetPage />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main room-table-list page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Bet ID
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Bet Name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Status
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE & TIME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Players
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"

                            >
                              Bet Coin
                            </Index.TableCell>


                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Winner Price
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="right"
                            >
                              Actions
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!loading ? currentUsers?.length ?
                            currentUsers?.map((row, index) => (
                              <Index.TableRow
                                key={row.name}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {checkData(row?.betId)}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td avatar-group"
                                  align="left"
                                >
                                  {checkData(row?.betName)}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="right"
                                  style={{
                                    color:
                                      row?.Status === "Active" ? "green" : "red",
                                  }}
                                >
                                  {row?.Status === "Inactive" ? "Deactive" : row?.Status}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {/* {moment(row.createdAt).format("DD-MM-YYYY")} */}
                                  {moment(row?.createdAt).format("lll")}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  {row?.players}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.betAmount ? row?.betAmount : 0}
                                </Index.TableCell>


                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="right"
                                >
                                  {row?.WinnerPrice ? row?.WinnerPrice : 0}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  align="center"
                                >
                                  <Index.Box className="bet-list-action-box">
                                    <AddBetPage rowData={row} />
                                    <Index.Switch
                                      color="warning"
                                      {...label}
                                      checked={row?.Status === "Active" ? true : false}
                                      onChange={(e) => {
                                        handleSwitchChange(
                                          row?._id,
                                          e.target.checked ? "Active" : "Inactive"
                                        )
                                        
                                      }}
                                    />
                                  </Index.Box>
                                </Index.TableCell>

                              </Index.TableRow>
                            )) : <NoRecordFound colSpan={12} /> : <Loading colSpan={12} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={pageNumbers?.length}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>



      </Index.Box>

    </>
  );
}