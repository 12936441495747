import React from "react";
import {
  BrowserRouter,
  Navigate,
  useLocation,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from "history";

//#region user
import { useSelector } from "react-redux";
//#endregion

//#region Admin
import Login from "../container/admin/auth/Login";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import ResetPassword from "../container/admin/auth/ResetPassword";
import PrivacyPolicy from "../container/admin/pages/cms/PrivacyPolicy";
import TermsAndCondition from "../container/admin/pages/cms/TermsAndCondition";
import GameRules from "../container/admin/pages/cms/GameRules";
import Otp from "../container/admin/auth/Otp";
import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import UserList from "../container/admin/pages/userlist/UserList";
import Home from "../container/admin/pages/home/Home";
import RoomList from "../container/admin/pages/room/RoomList";
import GeneralSetting from "../container/admin/pages/generalSetting/GeneralSetting";
import BetList from "../container/admin/pages/bet/BetList";
import UserDetails from "../container/admin/pages/userlist/UserDetails";
import WithDrawal from "../container/admin/pages/withdrawal/Withdrawal";
import CoinList from "../container/admin/pages/withdrawal/CoinList";
import UserChat from "../container/admin/pages/userlist/UserChat";
import Notification from "../container/admin/pages/notification/Notification"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "../component/common/pageNotFound/PageNotFound";
import UserTransactionHistory from "../container/admin/pages/userlist/UserTransactionHistory";

//#endregion

const ProtectedRoute = ({ children }) => {
  const adminToken = useSelector((state) => state.AdminReducer.token);
  let location = useLocation();

  if (!adminToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

const history = createBrowserHistory();
export default function Routes() {
  return (
    <BrowserRouter history={history}>
      <Routess>
      <Route path="*" element={<PageNotFound/>}/>
        <Route path="/" element={<Login />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="Otp" element={<Otp />} />

        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-profile"
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="privacy-policy"
            element={
              <ProtectedRoute>
                <PrivacyPolicy />
              </ProtectedRoute>
            }
          />
          <Route
            path="terms-and-condition"
            element={
              <ProtectedRoute>
                <TermsAndCondition />
              </ProtectedRoute>
            }
          />
          <Route
            path="game-rules"
            element={
              <ProtectedRoute>
                <GameRules />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-list"
            element={
              <ProtectedRoute>
                <UserList />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-chat/:roomId/:userId"
            element={
              <ProtectedRoute>
                <UserChat />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-detail-list/:id"
            element={
              <ProtectedRoute>
                <UserDetails />
              </ProtectedRoute>
            }
          />
           
          <Route
            path="withDrawal-list"
            element={
              <ProtectedRoute>
                <WithDrawal />
              </ProtectedRoute>
            }
          />
          <Route
            path="coin-list"
            element={
              <ProtectedRoute>
                <CoinList />
              </ProtectedRoute>
            }
          />
          <Route
            path="room-list"
            element={
              <ProtectedRoute>
                <RoomList />
              </ProtectedRoute>
            }
          />
          <Route
            path="general-setting"
            element={
              <ProtectedRoute>
                <GeneralSetting />
              </ProtectedRoute>
            }
          />
           <Route
            path="notification"
            element={
              <ProtectedRoute>
                <Notification />
              </ProtectedRoute>
            }
          />
          <Route
            path="bet-list"
            element={
              <ProtectedRoute>
                <BetList />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-transaction-history"
            element={
              <ProtectedRoute>
                <UserTransactionHistory/>
              </ProtectedRoute>
            }
          />
        </Route>
      </Routess>
      <ToastContainer/>
    </BrowserRouter>
  );
}
