let regEmail =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (!regEmail.test(val)) {
    return msg2;
  } else {
    return "";
  }
};

let regPassword =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const passwordCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
    // } else if (!regPassword.test(val)) {
    //   return msg2;
  } else {
    return "";
  }
};

export const confirmPasswordCheck = (val, val1, msg1, msg3) => {
  if (val === "") {
    return msg1;
    // } else if (!regPassword.test(val)) {
    //   return msg2;
  } else if (val !== val1) {
    return msg3;
  } else {
    return "";
  }
};

export const numberCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (val.length !== 10) {
    return msg2;
  } else {
    return "";
  }
};

export const valueCheck = (val, msg1) => {
  if (val === "") {
    return msg1;
  } else {
    return "";
  }
};

export const otpCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (val.length !== 4) {
    return msg2;
  } else {
    return "";
  }
};

let regLink = new RegExp(
  "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
);

export const linkCheck = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (!regLink.test(val)) {
    return msg2;
  } else {
    return "";
  }
};

let regNumber = new RegExp("^[0-9]+$");

export const number = (val, msg1, msg2) => {
  if (val === "") {
    return msg1;
  } else if (!regNumber.test(val)) {
    return msg2;
  } else {
    return "";
  }
};
