import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import Loading from "../../../../component/common/loading/Loading";
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";
import { useLocation } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function CoinList() {
  const token = useSelector((state) => state.AdminReducer.token);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = useState();
  const [status, setStatus] = useState("");
  const [select, setSelect] = React.useState("");
  const [id, setId] = useState({
    id: "",
    user: "",
  });
  const [openApprove, setOpenApprove] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoding] = useState(true)
// const {state}=useLocation()




  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleOpenApprove = () => setOpenApprove(true);
  const handleCloseApprove = () => setOpenApprove(false);


  const handlePageChange = (event, value) => {
    const val = Number(value);
    setCurrentPage(val);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChange = (event) => {
    setSelect(event.target.value);
    const filteredUsers = userList?.filter((item) => {
      const Status = item?.Status?.toLowerCase().includes(
        event?.target?.value?.toLowerCase()
      );
      return Status;
    });

    setFilterData(filteredUsers);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCoinList = async () => {
    await DataServices(token).get(
      Api.Admin.allTransaction + `?type=${"Add"}`
    ).then((response) => {
      if (response?.data?.status === 200) {
        setUserList(response?.data?.data);
        setFilterData(response?.data?.data);
        setLoding(false)
      }

    })

  };
  const onToggle = async () => {
    const formData = new URLSearchParams();
    formData.append("userId", id.user);
    formData.append("id", id.id);
    formData.append("Status", status);
    const response = await DataServices(token)
      .post(Api.Admin.accpetOrRejectTransaction, formData)
      .then((response) => {
        handleCloseDelete();
        handleCloseApprove();
        getCoinList();
      });
  };

  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = filterData?.slice(indexOfFirstUser, indexOfLastUser);


  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filterData?.length / userPerPage); i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    getCoinList();
  }, []);

 

    const onsearch = async (e) => {
    
      setSearchValue(e.target.value);
      const filteredUsers = userList?.filter((item) => {
        const fName = item?.user?.firstName
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
        const lName = item?.user?.lastName
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
          const fullName = `${item?.user?.firstName} ${item?.user?.lastName}`.toLowerCase();
          const isMatch = fullName?.includes( e.target.value.toLowerCase());
        const tranid = item?.transactionId
          ?.toString()
          .includes(e.target.value?.toString());
          const coins = item?.coins
          ?.toString()
          .includes(e.target.value?.toString());
        const Status = item?.Status?.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      //  const date=new Date(item?.date)
      //       .toLocaleDateString("en-GB")
      //       .includes(
      //         e.target.value
      //       );
        
        return fName || lName || tranid || Status ||coins ||isMatch ;
      });
      
      setFilterData(filteredUsers);
    };
 

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h5"
              variant="h5"
            >
              Request Coin List
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <Select
                value={select}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="user-detail-select"
              >
                <MenuItem value="">
                  All
                </MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Success">Success</MenuItem>
                <MenuItem value="Reject">Reject</MenuItem>
              </Select>
            </FormControl>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={searchValue}
                    onChange={onsearch}
                    placeholder="Search user"
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Transaction Id
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NAME
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE & TIME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Charges
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              COIN
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              AMOUNT
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!loading ? currentUsers.length ? currentUsers?.map((row, index) => (

                            <Index.TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.transactionId}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.user?.firstName +
                                  " " +
                                  row?.user?.lastName}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                style={{
                                  color:
                                    row.Status === "Success"
                                      ? "green"
                                      : row.Status === "Pending"
                                        ? "blue"
                                        : "red",
                                }}
                              >
                                {/* {row.Status[0].toUpperCase() +
                                  row.Status.slice(1)} */}

                                {row.Status === "Reject" ? "Rejected" : (row.Status === "Success" ? "Approved" : "Pending")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {moment(row.createdAt).format("lll")}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row?.charges}%
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.coins}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row?.amount}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                sx={{ cursor: 'pointer' }}
                              >
                                <MoreVertIcon
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  className={row.Status !== "Pending" ? "moreVertIcon-disabled" : ""}
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClose={handleClose}
                                  onClick={(event) => {
                                    handleClick(row.Status == "Reject" || row.Status == "Success" ? false : event);
                                    setId({
                                      id: row._id,
                                      user: row.user?._id,
                                    });
                                  }}
                                />
                                <Index.Menu
                                  id="basic-menu"

                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >

                                  <Index.MenuItem
                                    onClick={() => {
                                      handleOpenApprove(row._id, row.user?._id);
                                      handleClose();
                                      setStatus("Success");
                                    }}

                                  >
                                    Approve
                                  </Index.MenuItem>

                                  <Index.MenuItem
                                    onClick={() => {
                                      handleOpenDelete();
                                      handleClose();
                                      setStatus("Reject");
                                    }}

                                  >
                                    Reject
                                  </Index.MenuItem>
                                </Index.Menu>
                              </Index.TableCell>
                            </Index.TableRow>
                          )) : <NoRecordFound colSpan={12} /> : <Loading colSpan={12} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={pageNumbers.length}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openApprove}
        onClose={handleCloseApprove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
     <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.Box className="modal-circle-main-reject" onClick={handleCloseApprove}>
            <img src={Index.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to approve these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseApprove}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={() => {
                onToggle();
              }}
            >
              Approve
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main" onClick={handleCloseDelete}>
            <img src={Index.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to reject  these records?
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseDelete}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={() => {
                onToggle();
              }}
            >
              Reject
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
