import * as yup from "yup";
// const Joi = require("joi");

export const validateAdmin = yup.object({
  Email: yup.string().max(50).email().required(),
  PassWord: yup.string().min(8).required(),
});

//forgot password
export const validationSchemaForgotpassword = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid.')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid Email.'
    )
    .required("Please enter valid Email."),
});


// otp verify
export const validationSchemaOtpVerify = yup.object().shape({
  otp: yup.string()
    .required("Please enter OTP.")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number."),
});

// reset password
export const validationSchemaResetPassword = yup.object().shape({
  // password: yup.string().required("Password is required")
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
  // ),

  password: yup.string()
    .required('Please enter New Password.')
    .min(6, 'Password must be at least 6 characters.')
    .max(20, 'Password cannot exceed 20 characters.')
    .matches(/^(?=.*[0-9])/,
      'Password must contain at least one number.'
    ).matches(/^(?=.*[A-Z])/,
      'Password must contain at least one capital letter.'
    ).matches(/^(?=.*[@$!%*#?&])/,
      'Password must contain at least special character.'
    ).matches(/^(?=.*[a-z])/,
      'Password must contain at least small letter.'
    )
  ,
  confirm_password: yup
    .string()
    .required("Please enter Confirm Password.")
    .oneOf([yup.ref("password"), null],"Confirm Password should same as the password."),
});