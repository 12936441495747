import React, { useState } from "react";
import Index from "../../../component/Index";
import { Api } from "../../../config/Api";
import { DataServices } from "../../../config/DataService";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import ErrorShow from "../../../component/common/ErrorShow";
import { validationSchemaResetPassword } from "../../../validation/validation";
import { Formik } from "formik";

export default function ResetPassword() {
  
  // state  declaration
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const declaration

  const navigate = useNavigate();

  // value read declaration
  const { state } = useLocation();

  let resetId = state?state : null;
 
  // Initital values declaration
  let initialValues = {
    password: "",
    confirm_password: "",
  };

  // function declaration
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleFormSubmit = (values) => {
    // resetPasswordAction(values, state, navigate)
    DataServices()
          .post(Api.Admin.resetPassword,{password:values.password,forgotId:resetId })
          .then((response) => {
            toast.success(response?.data?.message);
              navigate("/")
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message);
          });
      }
  


  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Reset Password!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to ResetPassword!
                </Index.Typography>
                <Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={
                    validationSchemaResetPassword
                  }
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form
                      // component="form"
                      // spacing={2}
                      // noValidate
                      // autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          New password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            type={showNewPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowNewPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>

                        <ErrorShow error={errors.password} />
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <ErrorShow error={errors.confirm_password} />
                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          type="submit"
                          className="btn-primary login-btn"
                        >
                          Submit
                        </Index.Button>

                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
