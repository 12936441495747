import { SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Api } from "../../../../config/Api";
import { DataServices } from "../../../../config/DataService";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { adminUserChat } from "../../../../redux/admin/action";
import Loading from "../../../../component/common/loading/Loading";
import NoRecordFound from "../../../../component/common/NoRecordFound/NoRecordFound";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const label = { inputProps: { "aria-label": "Switch demo" } };

export default function UserList() {
  // add user modal
  const { token, Admin } = useSelector((state) => state?.AdminReducer);

  const [active, setActive] = useState(true);
  const [toggle, setToggle] = useState(false);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userPerPage, setUserPerPage] = useState(10);
  const handleClose = () => setOpen(false);
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(true)
  // delete modal
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  let { id } = useParams();
  const dispatch = useDispatch()
  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    const val = Number(value);
    setCurrentPage(val);
  };

  const onToggle = async (id, status) => {
    const formData = new URLSearchParams();
    formData.append("id", id);
    formData.append("status", status);
    const response = await DataServices().post(
      Api.Admin.activeandInactiveuser,
      formData
    );
    if(response.data.status===200){
      toast.success(response?.data?.message)
      getAllUsers();
    }
   
  };
  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );

  const getAllUsers = async () => {
    await DataServices(token).get(Api.Admin.getAllUsers).then((response) => {
      if (response?.data?.status===200) {
      
        setUserList(response?.data?.data)
        setFilterData(response?.data?.data)
        setLoading(false)
      }
    })
  };


  const indexOfLastUser = currentPage * userPerPage;
  const indexOfFirstUser = indexOfLastUser - userPerPage;
  const currentUsers = filterData?.slice(indexOfFirstUser, indexOfLastUser);



  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filterData?.length / userPerPage); i++) {
    pageNumbers.push(i);
  }
  const handleUserClick = (_id) => {
    navigate(`/admin/user-detail-list/${_id}`);
  };
  useEffect(() => {
    getAllUsers();
  }, []);



  const onsearch = async (e) => {
    setSearchValue(e.target.value);
    const filteredUsers = userList?.filter((item) => {
      const fName = item?.firstName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      const lName = item?.lastName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
      const email = item?.email
        .toLowerCase()
        .includes(e.target.value?.toLowerCase());
      const userid = item?.userId
        ?.toString()
        .includes(e.target.value?.toString());
      const reffer = item?.referralBy
        ?.toLowerCase()
        .includes(e.target.value.toLowerCase());
      const number = item?.mobileNumber
        .toString()
        .includes(e.target.value.toString());
      const status = item?.Status.toLowerCase().includes(
        e.target.value.toLowerCase()
      );
      return fName || lName || email || number || userid || reffer || status;
    });
    setFilterData(filteredUsers);
  };


  const adminChat = (userId, username, row) => {
    localStorage.setItem('userName', username);
    const urlencoded = new URLSearchParams();
    urlencoded.append("from", Admin?.adminId);
    urlencoded.append("to", userId);
    dispatch(adminUserChat(urlencoded, navigate, row));
  }

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            User List
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={searchValue}
                    onChange={onsearch}
                    placeholder="Search user"
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={Index.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box> */}
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={Index.Png.close}
                        className="filter-close-icon"
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>
            <Index.Box className="flex-all user-list-inner-flex">
              {/* <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                  onClick={handleOpenDelete}
                >
                  <img
                    src={Index.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box> */}
              {/* <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add User
                </Index.Button>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                          
                            >
                              USERID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE & TIME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              EMAIL
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              MOBILE NUMBER
                            </Index.TableCell>
                           
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              REFFEREL BY
                            </Index.TableCell>
                            
                           
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!loading ? currentUsers.length ? currentUsers?.map((row, index) => {
                            { var username = row?.firstName + " " + row?.lastName }
                            return (

                              <Index.TableRow
                                key={row.name}

                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td-click"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => handleUserClick(row?._id)}
                                  
                                >
                                  {row?.userId}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {row?.firstName + " " + row?.lastName}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  style={{
                                    color:
                                      row.Status === "Active" ? "green" : "red",
                                  }}
                                >
                                  {row?.Status[0]?.toUpperCase() +
                                    row?.Status?.slice(1)}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {moment(row?.createdAt)?.format("lll")}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.email}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.mobileNumber}
                                </Index.TableCell>
                             
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <Index.Tooltip title={row?.referringPerson} arrow>
                                    <span>{row?.referralBy}</span>

                                  </Index.Tooltip>



                                </Index.TableCell>
                                
                               
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Box className="user-list-actions">


                                    <Index.IconButton onClick={() => {

                                      adminChat(row?.userId, username, row)
                                    }} >
                                      <Index.MessageIcon className="message-icon" />
                                    </Index.IconButton>
                                    <Switch
                                      color="warning"
                                      {...label}
                                      checked={row?.Status === "Active"}
                                      onChange={(e) => {
                                        onToggle(
                                          row?._id,
                                          e.target.checked ? "Active" : "Inactive"
                                        );
                                     
                                      }}
                                    />
                                    {/* <EditIcon
                                  onClick={handleOpen}
                                  color="warning"
                                /> */}
                                  </Index.Box>
                                </Index.TableCell>

                              </Index.TableRow>
                            )
                          }) :
                            <NoRecordFound colSpan={12} />
                            : <Loading colSpan={12} />}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={pageNumbers.length}
                      onChange={handlePageChange}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Add User Modal
            </Index.Typography>
            <img src={Index.Svg.close} className="modal-close-icon" />
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              First Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Last Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email<span className="span-text common-para">(Optional)</span>
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Date
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button className="discard-user-btn border-btn">
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main">
            <img src={Index.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button className="modal-cancel-btn modal-btn">
              Cancel
            </Index.Button>
            <Index.Button className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

    </>
  );
}
