import { toast } from "react-toastify";
import type from "./type";


const initialState = {
  isAdminLogin: false,
  Admin: {},
  token: '',
  GeneralSetting: {},
  BetList: [],
  chatData: [],
  getGeneralSetting: {},
  loading: true,
  notificationData:{}
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.LOGOUT:
      
      return initialState;

    case type.AdminlogOutSuccess:
      return initialState;

    case type.ADMIN_LOGIN:
      return {
        ...state,
        Admin: action.payload.data,
        token: action.payload.data.token,
        isAdminLogin: true,
      };

    case type.EDIT_PROFILE:

      return {
        ...state,
        Admin: action.payload.data,
      }

    case type.GENERAL_SETTING:
      return {
        ...state,
        GeneralSetting: action.payload.data,
      }

    case type.GET_GENERAL_SETTING:

      return {
        ...state,
        getGeneralSetting: action.payload,
      }
    case type.BETLIST_LOADING:
      return {
        ...state,
        loading: true,
        BetList: "",
      }

    case type.BETLIST:
      return {
        ...state,
        loading: false,
        BetList: action.payload.data,
      }

    case type.ADD_BET:

      return {
        ...state,
        // BetList: action.payload.data,
      }

    case type.CHAT_API:
      return {
        ...state,
        chatData: action.payload.data,
      };
      case type.GET_NOTIFICATION_LOADING:
        return {
          ...state,
          loading:true,
          notificationData: '',
        };
        case type.GET_NOTIFICATION:
          return {
            ...state,
            loading:false,
            notificationData: action.payload.data,
          };

    default:
      return state;
  }
};

export default AdminReducer;
